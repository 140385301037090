import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../css/subathon.css';
import axios from 'axios';
import FlipNumbers from 'react-flip-numbers';
import ProgressBar from '../components/progress-bar';
import Clock from '../components/clock';
import Hourglass from '../components/hourglass';
import getYoutubeID from 'get-youtube-id';
import YouTube from 'react-youtube';
import Sockette from 'sockette';

const Subathon = () => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const { username } = useParams();
  const interval = useRef(null);
  const timerRef = useRef(0);
  const [playing, setPlaying] = useState(false);
  const [stopped, setStopped] = useState(true);
  const [timer, setTimer] = useState(0);
  const [maxDuration, setMaxDuration] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
  });
  const [showVideo, setShowVideo] = useState(false);
  const youtubeRef = useRef(null);

  useEffect(() => {
    const getSubathon = async () => {
      const { data } = await axios.get(`/pubapi/subathon?username=${username}`);
      applySettings(data);
    };
    getSubathon();
    setInterval(getSubathon, 60 * 1000)
  }, []);

  const applySettings = (data) => {
    let { startTime, duration, status, defaultTimer } = data;
    if (status == 'playing' && startTime != null && duration != null) {
      let diff = Math.round((Date.now() - startTime)/1000);
      setTimer(Math.max(0, duration - diff));
    } else if (status == 'paused' && duration != null) {
      setTimer(Math.max(0, duration));
    } else if (status == 'stopped' && defaultTimer != null) {
      setTimer(Math.max(0, defaultTimer));
    }
    if (status) {
      setPlaying(status == 'playing');
      setStopped(status == 'stopped');
    }
    setFormData({
      ...formData,
      ...data,
    });
    if (defaultTimer != null) {
      setTimeout(() => setMaxDuration(Math.max(duration || 0, defaultTimer)), 0);
    }
  };

  useEffect(() => {
    clearInterval(interval.current);
    if (playing) {
      interval.current = setInterval(() => {
        setTimer((timer) => --timer);
      }, 1000);
    } else {
      clearInterval(interval.current);
    }
  }, [playing]);

  useEffect(() => {
    if (stopped === true && formData.defaultTimer) {
      setMaxDuration(formData.defaultTimer);
    }
  }, [stopped, formData.defaultTimer]);

  useEffect(() => {
    let timerValue = timer;
    if (timer <= 0) {
      setPlaying(false);
      setStopped(true);
      if (timerRef.current == 1) {
        finished();
      }
      timerValue = 0;
    }
    timerRef.current = timerValue;

    setHour(Math.floor(timerValue/3600));
    setMinute(Math.floor(timerValue/60)%60);
    setSecond(timerValue%60);
  }, [timer]);

  const finished = async () => {
    if (formData.finishMedia) {
      const regexTimestamp = /[?&]t=([0-9]+)/;
      const match = formData.finishMedia.match(regexTimestamp);
      let videoId = getYoutubeID(formData.finishMedia);
      youtubeRef.current.internalPlayer.loadVideoById({
        videoId,
        startSeconds: match ? match[1] : 0,
      });
      youtubeRef.current.internalPlayer.addEventListener(
        'onStateChange',
        youtubeStateChange
      );
      setShowVideo(true);
      try {
        await youtubeRef.current.internalPlayer.setVolume(100);
        await youtubeRef.current.internalPlayer.playVideo();
      } catch (err) {
        console.error('Error playing media', err);
      }
    }
  };

  useEffect(() => {
    if (!showVideo) {
      youtubeRef.current.internalPlayer.pauseVideo();
    }
  }, [showVideo]);

  const youtubeStates = {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    CUED: 5,
  };

  const youtubeStateChange = (e) => {
    switch (e.data) {
      case youtubeStates.ENDED:
        setShowVideo(false);
        break;
      case youtubeStates.BUFFERING:
        //youtubeBuffering();
        break;
    }
  };

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
    },
  };

  const processMessage = (message) => {
    if (message.add) {
      setTimer(Math.max(0, Math.floor(timerRef.current + message.add)));
      let duration = Number(formData.duration) + Number(message.add);
      setFormData((formData) => ({
        ...formData,
        duration,
      }));
      setMaxDuration((maxDuration) => Math.max(duration, maxDuration));
    }
    if (message.duration) {
      setTimer(message.duration);
      setFormData((formData) => ({
        ...formData,
        duration: message.duration,
      }));
      setMaxDuration((maxDuration) => Math.max(message.duration, maxDuration));
    }
    if (message.play != null) {
      if (message.duration) {
        setTimer(message.duration);
      }
      setPlaying(message.play);
      setStopped(false);
      setShowVideo(false);
    }
    if (message.stop == true) {
      setPlaying(false);
      setStopped(true);
    }
    if (message.defaultTimer) {
      setFormData((formData) => ({
        ...formData,
        defaultTimer: message.defaultTimer,
      }));
      setMaxDuration(message.defaultTimer);
    }
    if (message.settings) {
      if (message.settings.status != 'stopped') {
        delete message.settings.duration;
      }
      applySettings(message.settings);
    }
  };

  useEffect(() => {
    const sockette = new Sockette(`wss://${baseUrl}/${username.toLowerCase()}_subathon`,
      {
        onopen: function (event) {
          event.target.send(`Remote client log - ${username} - subathon opened`);
        },
        onmessage: function (event) {
          event.target.send(`Remote client log - ${username} subathon - [message] Data received from server: ${event.data}`);
          if (event.data == 'pong') {
            return;
          }

          const message = JSON.parse(event.data);
          processMessage(message);
        },
        onclose: function (event) {
          if (event.wasClean) {
            console.info(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
          } else {
            console.error(`[close] Connection died`);
          }
        },
        onerror: function (error) {
          console.error(`[error] ${error.message}`);
        },
      }
    );
    setInterval(() => {
      sockette && sockette.send(`ping from: ${username} - subathon`);
    }, 30 * 1000);
  }, []);

  return (
    <div className={"subathon-container " + formData.textFont} style={{ backgroundColor: formData.backgroundColor }}>
      <h3 style={{ fontSize: formData.titleFontSize, color: formData.textColor }}>{formData.title}</h3>
      <div className="visual-container">
      {!showVideo && formData.clockStyle == 'clock' ? (
        <Clock
          hour={hour}
          minute={minute}
          second={second}
          primaryColor={formData.clockPrimaryColor}
          secondaryColor={formData.clockSecondaryColor}
          backgroundColor={formData.clockBackgroundColor}
          radius={110}
          roman={false}
        />
      ) : !showVideo && formData.clockStyle == 'clock-roman' ? (
        <Clock
          hour={hour}
          minute={minute}
          second={second}
          primaryColor={formData.clockPrimaryColor}
          secondaryColor={formData.clockSecondaryColor}
          backgroundColor={formData.clockBackgroundColor}
          radius={110}
          roman={true}
        />
      ) : !showVideo && formData.clockStyle == 'hourglass' ? (
        <Hourglass
          value={timer}
          max={maxDuration}
          primaryColor={formData.clockPrimaryColor}
          secondaryColor={formData.clockSecondaryColor}
          backgroundColor={formData.clockBackgroundColor}
          playing={playing}
        />
      ) : !showVideo && formData.clockStyle && formData.clockStyle.match(/^goal/g) ? (
        <ProgressBar
          value={timer}
          theme={formData.clockStyle.replace(/^goal-/g, '')}
          max={maxDuration}
          primaryColor={formData.clockPrimaryColor}
          secondaryColor={formData.clockSecondaryColor}
          backgroundColor={formData.clockBackgroundColor}
          textColor={formData.textColor}
          isMonetary={false}
          fontSize={formData.fontSize}
          length="750px"
        />
      ) : <div/>}
      </div>
      <div className="flex-row time" style={{ fontSize: formData.timerFontSize, color: formData.timerColor }}>
        <FlipNumbers
          height={formData.timerFontSize}
          width={formData.timerFontSize}
          background='transparent'
          play
          perspective={300}
          numbers={String(hour).padStart(2, '0')}
        />:
        <FlipNumbers
          height={formData.timerFontSize}
          width={formData.timerFontSize}
          background='transparent'
          play
          perspective={300}
          numbers={String(minute).padStart(2, '0')}
        />:
        <FlipNumbers
          height={formData.timerFontSize}
          width={formData.timerFontSize}
          background='transparent'
          play
          perspective={300}
          numbers={String(second).padStart(2, '0')}
        />
      </div>
      <div
        className="flex-center"
        style={{ display: showVideo ? 'flex' : 'none' }}
      >
        <YouTube ref={youtubeRef} opts={opts} />
      </div>
      <div style={{ color: formData.textColor }} className="description">{formData.description}</div>
    </div>
  );
};

export default Subathon;
