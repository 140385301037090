import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../css/paid-chat-page.css';
import useAuth from '../hooks/useAuth';
import Card from '../components/card';
import Donations from '../components/donations';
import Subscriptions from '../components/subscriptions';
import AboutSection from '../components/about-section';
import AccountDisabled from './account-disabled';
import PageError from '../pages/page-error';

const PaidChat = () => {
  const tipUsername = useParams().username && useParams().username.toLowerCase();
  const [tipUserId, setTipUserId] = useState(null);
  const location = useLocation();
  const { user, isProViewer } = useAuth();
  const [visibleTab, setVisibleTab] = useState('donation');
  const [pageLoaded, setPageLoaded] = useState(false);
  const [foundUser, setFoundUser] = useState(null);
  const [accountDisabled, setAccountDisabled] = useState(false);
  const [captchaDone, setCaptchaDone] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [paidChatName, setPaidChatName] = useState('');
  const [dropdownSpace, setDropdownSpace] = useState(0);
  const [paidChatData, setPaidChatData] = useState({});
  const [subscription, setSubscription] = useState({});
  const [followerCount, setFollowerCount] = useState(0);
  const profanity = useRef([]);

  const tabs = ['donation', 'subscription', 'about'];

  useEffect(() => {
    const getPaidChatName = async () => {
      try {
        const { data } = await axios.get('/paid-chat-name/' + tipUsername);
        setPaidChatName(data.displayName || data.username);
        setAccountDisabled(data.accountDisabled);
        setTipUserId(data.userId);
        setFoundUser(true);
        loadTab();
      } catch (err) {
        console.error(err);
        setFoundUser(false);
      } finally {
        setPageLoaded(true);
      }
    };
    const loadTab = () => {
      let urlParams = new URLSearchParams(window.location.search);
      let tabName = urlParams.get('tab');
      tabName = tabName ? tabName.toLowerCase() : 'donation';
      tabName = tabs.includes(tabName) ? tabName : 'donation';
      selectTab(tabName.toLowerCase());
    };
    getPaidChatName();
  }, [tipUsername]);

  useEffect(() => {
    const getUserSettings = async () => {
      try {
        const { data } = await axios.get('/pubapi/user/' + tipUsername);
        let donationAmounts = new Set([3, 10, 15, 20]);
        donationAmounts.add(parseInt(data.ttsLimit));
        donationAmounts.add(parseInt(data.mediaLimit));
        donationAmounts.add(parseInt(data.extendedMediaLimit));
        donationAmounts = Array.from(donationAmounts)
          .filter((e) => e)
          .sort((a, b) => a - b)
          .slice(0, 4);
        setPaidChatData(data);
      } catch (err) {
        console.error(err);
      }
    };
    getUserSettings();
  }, [location]);

  useEffect(() => {
    const getSubData = async () => {
      let result = await axios.get(`/api/subscription/${user.username}/${tipUsername}`);
      setSubscription(result.data);
    };
    getSubData();
  }, [location]);

  useEffect(() => {
    const getProfanity = async () => {
      try {
        const { data } = await axios.get('/pubapi/profanity');
        profanity.current = data.profanity.filter(x => x.deepfake_block);
      } catch (err) {
        console.error(err);
      }
    };
    getProfanity();
  }, []);

  const handleFollowerCountChange = (newCount) => {
    setFollowerCount(newCount);
  };

  const { ttsVoice, mediaLimit } = paidChatData;

  const selectTab = (tab) => {
    window.history.replaceState(null, null, '?tab=' + tab);
    setVisibleTab(tab);
  };
  return pageLoaded === false ? (
    <div/>
  ) : foundUser === false ? (
    <PageError errorCode={404} />
  ) : accountDisabled === true ? (
    <AccountDisabled />
  ) : (
    <div className="page-container">
      <div className="paid-chat-page-wrapper">
        <div
          className="paid-chat-page-container"
          style={{
            backgroundImage: `linear-gradient(to bottom, transparent, var(--black)), url(${paidChatData.bannerUrl})`,
          }}
        >
          <div className="paid-chat-page">
            <Card>
              <div>
                <h2 className="paid-chat-name">
                  {paidChatName}{' '}
                  <div className="follower-count-display">
                    {followerCount}{' '}
                    {followerCount === 1 ? 'follower' : 'followers'}
                  </div>
                </h2>
                <div className="paid-chat-tabs">
                  <button
                    onClick={() => selectTab('donation')}
                    className={
                      'secondary-btn ' +
                      `${visibleTab === 'donation' ? 'active' : ''}`
                    }
                  >
                    Powerchat
                  </button>
                  <button
                    onClick={() => selectTab('subscription')}
                    className={
                      'secondary-btn ' +
                      `${visibleTab === 'subscription' ? 'active' : ''}`
                    }
                  >
                    Subscribe
                  </button>
                  <button
                    onClick={() => selectTab('about')}
                    className={
                      'secondary-btn ' +
                      `${visibleTab === 'about' ? 'active' : ''}`
                    }
                  >
                    About
                  </button>
                </div>
                {visibleTab === 'donation' ? (
                  <Donations
                    user={user}
                    tipUsername={tipUsername}
                    tipUserId={tipUserId}
                    paidChatData={paidChatData}
                    subscription={subscription}
                    ttsVoice={ttsVoice}
                    mediaLimit={mediaLimit}
                    setDropdownSpace={setDropdownSpace}
                    captchaDone={captchaDone}
                    setCaptchaDone={setCaptchaDone}
                    captchaToken={captchaToken}
                    setCaptchaToken={setCaptchaToken}
                    profanity={profanity}
                    onFollowerCountChange={handleFollowerCountChange}
                    isProViewer={isProViewer}
                  />
                ) : visibleTab === 'subscription' ? (
                  <Subscriptions
                    user={user}
                    tipUsername={tipUsername}
                    tipUserId={tipUserId}
                    ttsVoice={ttsVoice}
                    paidChatData={paidChatData}
                    subscription={subscription}
                    captchaDone={captchaDone}
                    setCaptchaDone={setCaptchaDone}
                    captchaToken={captchaToken}
                    setCaptchaToken={setCaptchaToken}
                    profanity={profanity}
                  />
                ) : (
                  <AboutSection
                    links={paidChatData.userLinks}
                    description={paidChatData.description}
                  />
                )}
              </div>
            </Card>
            <div
              style={{ height: dropdownSpace }}
              className="dropdown-space"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaidChat;
