import React, { useState, useEffect } from 'react';
import '../../css/user-moderation.css';
import axios from 'axios';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../hooks/useAuth';
import ToggleSwitch from '../toggle-switch';
import FormAlert from '../form-alert';
import Events from '../events';
import SettingsForm from '../settings-form';
import OverlaySettings from '../overlay-settings';
import Personalization from '../personalization';
import Goal from '../goal';
import SecurityForm from '../security-form';
import Controls from '../controls';
import PaymentIntegrations from '../payment-integrations';
import ExternalIntegrations from '../external-integrations';
import Following from '../following';

const UserModeration = ({ open, onClose, userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isAdmin } = useAuth();
  const [visibleTab, setVisibleTab] = useState('overview');
  const [changeUsernameOpen, setChangeUsernameOpen] = useState(false);
  const [usernameHistoryOpen, setUsernameHistoryOpen] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    created: null,
    accountDisabled: false,
    modNotes: '',
    confirmed: false,
    shadowbanned: false,
    paypalLegacy: false,
    cozytvEnabled: false,
    newUsername: '',
    isProViewer: false,
    isProStreamer: false,
    isGranted: false,
  });
  const [modLog, setModLog] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [accountSettings, setAccountSettings] = useState({});
  const [securitySettings, setSecuritySettings] = useState({});
  const [goalSettings, setGoalSettings] = useState({});
  const [usernameHistory, setUsernameHistory] = useState([]);
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const tabs = {
    overview: 'Overview',
    security: 'Security',
    events: 'Events',
    settings: 'TTS Settings',
    'overlay-settings': 'Overlay Settings',
    controls: 'Controls',
    personalization: 'Personalization',
    goal: 'Goal',
    'payment-integrations': 'Payment Integrations',
    'external-integrations': 'External Integrations',
    following: 'Following',
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        setIsLoading(true);
        const modData = (await axios.get(`/modapi/user-moderation/${userId}`)).data;
        const settings = (await axios.get(`/modapi/dashboard/${userId}`)).data;
        const twitchSettings = (await axios.get(`/modapi/twitch-settings/${userId}`)).data;
        const trovoSettings = (await axios.get(`/modapi/trovo-settings/${userId}`)).data;
        const kickSettings = (await axios.get(`/modapi/kick-settings/${userId}`)).data;
        const cozySettings = (await axios.get(`/modapi/cozy-settings/${userId}`)).data;
        const dliveSettings = (await axios.get(`/modapi/dlive-settings/${userId}`)).data;
        const xSettings = (await axios.get(`/modapi/x-settings/${userId}`)).data;
        const youtubeSettings = (await axios.get(`/modapi/youtube-settings/${userId}`)).data;
        const robotstreamerSettings = (await axios.get(`/modapi/robotstreamer-settings/${userId}`)).data;
        const rumbleSettings = (await axios.get(`/modapi/rumble-settings/${userId}`)).data;
        const overlaySettings = (await axios.get(`/modapi/overlay-settings/${userId}`)).data;
        const accountSettings = (await axios.get(`/modapi/account-settings/${userId}`)).data;
        const securitySettings = (await axios.get(`/modapi/security-settings/${userId}`)).data;
        const goalSettings = (await axios.get(`/modapi/goals/${userId}`)).data;

        setModLog(modData.modLog);
        setUsernameHistory(modData.usernameHistory);
        setDashboardData({
          ...settings,
          overlaySettings,
          youtubeSettings,
          rumbleSettings,
          robotstreamerSettings,
          dliveSettings,
          cozySettings,
          twitchSettings,
          trovoSettings,
          kickSettings,
          xSettings,
        });
        delete modData['modLog'];
        delete modData['usernameHistory'];
        setFormData(modData);
        setAccountSettings(accountSettings);
        setSecuritySettings(securitySettings);
        setGoalSettings(goalSettings);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    if (userId) {
      getUser();
    }

    setAlertState({
      message: '',
      success: true,
    });
  }, [userId]);

  useEffect(() => {
    setAlertState({
      message: '',
      success: true,
    });
  }, [visibleTab]);

  const onTabClick = (name) => {
    setVisibleTab(name);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const changeUsername = async (event) => {
    event.preventDefault();
    try {
      let { newUsername, username } = formData;
      if (!confirm(`Save ${newUsername} as new username?`)) {
        return;
      }
      let response = await axios.post(`/admapi/change-username`, {
        userId,
        oldUsername: username,
        newUsername,
      });
      if (response.data.modLog) {
        setModLog(cur => [...response.data.modLog, ...cur]);
      }
      triggerAlert('Username changed successfully');
      setFormData({
        ...formData,
        username: newUsername,
      });
      setChangeUsernameOpen(false);
    } catch (err) {
      console.error(err);
      triggerAlert('Could not change username', false);
      setChangeUsernameOpen(false);
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      newUsername: '',
    });
  }, [changeUsernameOpen]);

  const grantPro = async (planId, name) => {
    if (!confirm(`Grant ${formData.username} Powerchat ${name}?`)) {
      return;
    }
    try {
      let response = await axios.post('/admapi/user-moderation/powerchat-pro/grant', {
        userId,
        planId,
      });
      let key = planId > 2 ? 'isProStreamer' : 'isProViewer';
      setFormData({
        ...formData,
        isGranted: true,
        [key]: true,
      });
      if (response.data.modLog) {
        setModLog(cur => [...response.data.modLog, ...cur]);
      }

      triggerAlert(name + ' granted');
    } catch (err) {
      console.error(err);
      triggerAlert('Failed to grant ' + name, false);
    }
  };

  const revokePro = async (name) => {
    if (!confirm(`Revoke Powerchat ${name} from ${formData.username}?`)) {
      return;
    }
    try {
      let response = await axios.post('/admapi/user-moderation/powerchat-pro/revoke', { userId });
      setFormData({
        ...formData,
        isGranted: false,
        isProViewer: response.data.isProViewer,
        isProStreamer: response.data.isProStreamer,
      });
      if (response.data.modLog) {
        setModLog(cur => [...response.data.modLog, ...cur]);
      }
      triggerAlert(name + ' revoked');
    } catch (err) {
      console.error(err);
      triggerAlert('Failed to revoke ' + name, false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let response = await axios.post(`/modapi/user-moderation/${userId}`, formData);
      if (response.data.modLog) {
        setModLog(cur => [...response.data.modLog, ...cur]);
      }
      triggerAlert('Changes saved successfully');
    } catch (err) {
      console.error(err);
      triggerAlert('Could not save changes', false);
    }
  };

  const formatDate = (date) => format(new Date(date), 'MMM d, yyyy kk:mm:ss');

  const {
    ttsLimit,
    mediaLimit,
    extendedMediaLimit,
    mediaDuration,
    extendedMediaDuration,
    maxTtsSeconds,
    bannerUrl,
    tagLine,
    description,
    ttsImage,
    ttsSound,
    ttsVoice,
    subscribeImage,
    subscribeSound,
    subscribeMute,
    followImage,
    followSound,
    followMute,
    ttsFont,
    ttsAnimation,
    useDefaultProfanity,
    useDefaultMediaBlacklist,
    useDefaultChatFilter,
    blacklist,
    mediaBlacklist,
    mediaMinViews,
    mediaEnabled,
    showVideo,
    playTtsTitle,
    donatorSelectVoice,
    playbackEnabled,
    playbackDelay,
    ttsVolume,
    stripeUserId,
    stripeExpress,
    stripeEnabled,
    squareUserId,
    squareEnabled,
    paypalEmail,
    paypalScrape,
    paypalMerchantId,
    paypalEnabled,
    paypalLegacy,
    cryptoEnabled,
    cryptoAddress,
    cryptoBalance,
    cryptoInstantAlerts,
    cozytvEnabled,
    cozySettings,
    slAccessToken,
    robotstreamerSettings,
    twitchSettings,
    youtubeSettings,
    trovoSettings,
    kickSettings,
    rumbleSettings,
    dliveSettings,
    xSettings,
    twoFaSetup,
    coinName,
    coinCode,
    coingeckoId,
    overlaySettings,
    userLinks,
    customDonationAmounts,
  } = dashboardData;

  return (
    <Dialog open={open}
      onClose={onClose}
      classes={{ paper: 'user-mod-dialog' }}
    >
      <DialogTitle>
        <div className="dialog-title flex-row">
          <div className="crop-wrapper">
            <img src={formData.avatar || `/static/img/user-default.jpg`} />
          </div>
          {formData.username}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </div>
        <div className="tabs">
          {Object.keys(tabs).map(tab => (
            <div key={tab} className={visibleTab == tab ? 'selected' : ''} onClick={() => onTabClick(tab)}>
              {tabs[tab]}
            </div>
          ))}
          </div>
      </DialogTitle>
      <DialogContent>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="dialog-container">
        {visibleTab === 'overview' && (
          <div className="cards-container">
            <form style={{flex:1}} className='card user-mod-form' onSubmit={handleSubmit}>
              <div>
                <label>Username:</label>
                <div className="readonly-field">{formData.username}</div>&emsp;
                {isAdmin && (<div><a onClick={() => setChangeUsernameOpen(true)}>edit</a>&emsp;</div>)}
                <a onClick={() => setUsernameHistoryOpen(true)}>view history</a>
              </div>
              <div>
                <label>Email:</label>
                <div className="readonly-field">{formData.email}</div>
              </div>
              <div>
                <label>Avatar:</label>
                <div className="readonly-field">{formData.avatar}</div>
              </div>
              <div>
                <label style={{marginRight: 5}}>Created on</label>
                <div className="readonly-field">
                {formData.created && (
                  formatDate(formData.created)
                )}
                </div>
              </div>
              <div>
                <label>Confirmed? {formData.confirmed}</label>
                {formData.confirmed ? (
                  <FontAwesomeIcon color="#ffffff" icon={faCircleCheck} />
                ) : (
                  <FontAwesomeIcon color="#ffffff" icon={faCircleXmark} />
                )}
              </div>
              <div className="toggle-row">
                <ToggleSwitch
                  name="accountDisabled"
                  onChange={() => handleToggle('accountDisabled')}
                  checked={formData.accountDisabled}
                  disabled={!isAdmin}
                />
                <label htmlFor="accountDisabled">Suspend User</label>
                <ToggleSwitch
                  name="shadowbanned"
                  onChange={() => handleToggle('shadowbanned')}
                  checked={formData.shadowbanned}
                  disabled={!isAdmin}
                />
                <label htmlFor="shadowbanned">Shadowban User</label>
              </div>
              <div className="toggle-row">
                <ToggleSwitch
                  name="paypalLegacy"
                  onChange={() => handleToggle('paypalLegacy')}
                  checked={formData.paypalLegacy}
                  disabled={!isAdmin}
                />
                <label htmlFor="paypalLegacy">PayPal Enabled</label>
                <ToggleSwitch
                  name="cozytvEnabled"
                  onChange={() => handleToggle('cozytvEnabled')}
                  checked={formData.cozytvEnabled}
                  disabled={!isAdmin}
                />
                <label htmlFor="cozytvEnabled">Cozy.tv Enabled</label>
              </div>
              <div>
                <label>Powerchat Pro:</label>
                <div className="readonly-field">
                  {formData.isProStreamer ? 'Pro Streamer' : formData.isProViewer ? 'Pro Viewer' : 'None'}
                </div>
              </div>
              <div className="pro-grant">
                {isAdmin && !formData.isProViewer && (
                  <button type="button" onClick={() => grantPro(2, 'Pro Viewer')}>Grant Pro Viewer</button>
                )}
                {isAdmin && !formData.isProStreamer && formData.isProViewer && formData.isGranted && (
                  <button type="button" className="revoke" onClick={() => revokePro('Pro Viewer')}>Revoke Pro Viewer</button>
                )}
                {isAdmin && formData.isGranted && !formData.isProStreamer && (
                  <button type="button" onClick={() => grantPro(4, 'Pro Streamer')}>Grant Pro Streamer</button>
                )}
                {isAdmin && formData.isProStreamer && formData.isGranted && (
                  <button type="button" className="revoke" onClick={() => revokePro('Pro Streamer')}>Revoke Pro Streamer</button>
                )}
              </div>
              <label htmlFor="modNote">Moderation Notes</label>
              <textarea
                name="modNotes"
                maxLength={10000}
                onChange={handleChange}
                value={formData.modNotes || ''}
              />
              <div>
                <button style={{marginTop: 5}} className="primary-btn" type="submit">Save</button>
                <FormAlert alertState={alertState} width={400} height={40} margin={0} />
              </div>
            </form>
            <div className="card">
              <h4>Moderation Log</h4>
              <table>
                <thead>
                  <tr>
                    <td>User ID</td>
                    <td>Moderator</td>
                    <td>Modified</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                {modLog.map(log => (
                  <tr key={log.id}>
                    <td>{log.modId}</td>
                    <td>{log.modName}</td>
                    <td className="log-date">{formatDate(log.createdAt)}</td>
                    <td className="log-action" title={log.description}>{log.description}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {visibleTab === 'security' && (
          <div className="card">
            <SecurityForm email={formData.email}
              isMod={true}
              userId={userId}
              securitySettings={securitySettings}
            />
          </div>
        )}
        {visibleTab === 'events' && (
          <div className="card">
            <Events userId={userId} username={formData.username} isMod={true} />
          </div>
        )}
        {visibleTab === 'settings' && (
          <div className="card">
            <SettingsForm
              isMod={true}
              ttsLimit={ttsLimit}
              mediaLimit={mediaLimit}
              extendedMediaLimit={extendedMediaLimit}
              mediaDuration={mediaDuration}
              extendedMediaDuration={extendedMediaDuration}
              maxTtsSeconds={maxTtsSeconds}
              customDonationAmounts={customDonationAmounts}
              ttsImage={ttsImage}
              ttsSound={ttsSound}
              subscribeImage={subscribeImage}
              subscribeSound={subscribeSound}
              subscribeMute={subscribeMute}
              followImage={followImage}
              followSound={followSound}
              followMute={followMute}
              ttsVoice={ttsVoice}
              ttsFont={ttsFont}
              ttsAnimation={ttsAnimation}
              useDefaultProfanity={useDefaultProfanity}
              useDefaultMediaBlacklist={useDefaultMediaBlacklist}
              useDefaultChatFilter={useDefaultChatFilter}
              blacklist={blacklist}
              mediaBlacklist={mediaBlacklist}
              mediaMinViews={mediaMinViews}
              mediaEnabled={mediaEnabled}
              showVideo={showVideo}
              playTtsTitle={playTtsTitle}
              donatorSelectVoice={donatorSelectVoice}
              ttsDefaultColors={overlaySettings.ttsDefaultColors}
              ttsSubscriberColor={overlaySettings.ttsSubscriberColor}
              ttsDonatorColor={overlaySettings.ttsDonatorColor}
              ttsMessageColor={overlaySettings.ttsMessageColor}
              updateData={() => {}}
            />
          </div>
        )}
        {visibleTab === 'overlay-settings' && (
          <div className="card">
            <OverlaySettings
              isMod={true}
              viewCountOverlayOrientation={overlaySettings.viewCountOverlayOrientation}
              viewCountOrientation={overlaySettings.viewCountOrientation}
              viewCountPowerchatViewers={overlaySettings.viewCountPowerchatViewers}
              emoteWallAnimation={overlaySettings.emoteWallAnimation}
              emoteWallMaxEmoteSize={overlaySettings.emoteWallMaxEmoteSize}
              emoteWallSpeed={overlaySettings.emoteWallSpeed}
              chatOverlayTtl={overlaySettings.chatOverlayTtl}
              chatOverlayEmoteSize={overlaySettings.chatOverlayEmoteSize}
              chatOverlayMessageColor={overlaySettings.chatOverlayMessageColor}
              chatOverlayMessageColorRandom={overlaySettings.chatOverlayMessageColorRandom}
              chatOverlayChatterColor={overlaySettings.chatOverlayChatterColor}
              chatOverlayChatterColorRandom={overlaySettings.chatOverlayChatterColorRandom}
              chatOverlayChatterColorDefault={overlaySettings.chatOverlayChatterColorDefault}
              chatOverlayEmotesOnly={overlaySettings.chatOverlayEmotesOnly}
              chatOverlayNoEmotes={overlaySettings.chatOverlayNoEmotes}
              chatOverlayExpirationAnimation={overlaySettings.chatOverlayExpirationAnimation}
              chatOverlayIncomingAnimation={overlaySettings.chatOverlayIncomingAnimation}
              chatOverlayNoBadges={overlaySettings.chatOverlayNoBadges}
              chatOverlayNoAvatars={overlaySettings.chatOverlayNoAvatars}
              chatOverlayNoUsernames={overlaySettings.chatOverlayNoUsernames}
              chatOverlayTheme={overlaySettings.chatOverlayTheme}
              chatOverlayOrientation={overlaySettings.chatOverlayOrientation}
              chatOverlayFont={overlaySettings.chatOverlayFont}
              chatOverlayFontSize={overlaySettings.chatOverlayFontSize}
              chatOverlayBackgroundColor={overlaySettings.chatOverlayBackgroundColor}
              chatOverlayBackgroundTitleColor={overlaySettings.chatOverlayBackgroundTitleColor}
              chatOverlayBackgroundMessageColor={overlaySettings.chatOverlayBackgroundMessageColor}
              ttsChatsMode={overlaySettings.ttsChatsMode}
              updateData={() => {}}
            />
          </div>
        )}
        {visibleTab === 'controls' && (
          <div className="card">
            <Controls
              mediaEnabled={mediaEnabled}
              playbackEnabled={playbackEnabled}
              ttsVolume={ttsVolume}
              playbackDelay={playbackDelay}
              ttsChatsMode={overlaySettings.ttsChatsMode}
              ttsChatsVolume={overlaySettings.ttsChatsVolume}
              updateData={() => {}}
              user={formData.username}
              isMod={true}
            />
          </div>
        )}
        {visibleTab === 'personalization' && (
          <div className="card">
            <Personalization
              isMod={true}
              bannerUrl={bannerUrl}
              tagLine={tagLine}
              description={description}
              userLinks={userLinks}
              displayName={formData.displayName}
              updateData={() => {}}
            />
          </div>
        )}
        {visibleTab === 'goal' && (
          <div className="card">
            <Goal
              isMod={true}
              goalSettings={goalSettings}
              updateData={() => {}}
            />
          </div>
        )}
        {visibleTab === 'payment-integrations' && (
          <div className="card">
            <PaymentIntegrations
              isMod={true}
              username={formData.username}
              stripeUserId={stripeUserId}
              stripeEnabled={stripeEnabled}
              stripeExpress={stripeExpress}
              squareUserId={squareUserId}
              squareEnabled={squareEnabled}
              paypalEmail={paypalEmail}
              paypalEnabled={paypalEnabled}
              paypalLegacy={paypalLegacy}
              paypalMerchantId={paypalMerchantId}
              paypalScrape={paypalScrape}
              cryptoEnabled={cryptoEnabled}
              cryptoAddress={cryptoAddress}
              cryptoBalance={cryptoBalance}
              cryptoInstantAlerts={cryptoInstantAlerts}
              coinName={coinName}
              coinCode={coinCode}
              coingeckoId={coingeckoId}
              updateData={() => {}}
              twoFaSetup={twoFaSetup}
            />
          </div>
        )}
        {visibleTab === 'external-integrations' && (
          <div className="card">
            <ExternalIntegrations
              isMod={true}
              robotstreamerSettings={robotstreamerSettings}
              cozytvEnabled={cozytvEnabled}
              cozySettings={cozySettings}
              twitchSettings={twitchSettings}
              youtubeSettings={youtubeSettings}
              trovoSettings={trovoSettings}
              kickSettings={kickSettings}
              rumbleSettings={rumbleSettings}
              dliveSettings={dliveSettings}
              xSettings={xSettings}
              slAccessToken={slAccessToken}
              overlaySettings={overlaySettings}
              updateData={() => {}}
            />
          </div>
        )}
        {visibleTab === 'following' && (
          <div className="card">
            <Following
              selfUsername={formData.username}
              list={accountSettings.following}
              hasTooltip={false}
              isMod={true}
            />
          </div>
        )}
        </div>
      )}
        <Dialog open={changeUsernameOpen}
          onClose={() => setChangeUsernameOpen(false)}
          classes={{ paper: 'username-history-dialog' }}
        >
          <DialogTitle>
            <div className="dialog-title flex-row">
              Change Username
              <IconButton
                aria-label="close"
                onClick={() => setChangeUsernameOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="card form-container dialog-container">
              <h4>{formData.username}</h4>
              <form onSubmit={changeUsername}>
                <input
                  name="newUsername"
                  placeholder="New Username"
                  type="text"
                  value={formData.newUsername}
                  onChange={handleChange}
                  disabled={!isAdmin}
                />
                <button type="submit" className="primary-btn">Save</button>
              </form>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={usernameHistoryOpen}
          onClose={() => setUsernameHistoryOpen(false)}
          classes={{ paper: 'username-history-dialog' }}
        >
          <DialogTitle>
            <div className="dialog-title flex-row">
              Username History
              <IconButton
                aria-label="close"
                onClick={() => setUsernameHistoryOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="card">
              <h4>{formData.username}</h4>
              <table>
                <thead>
                  <tr>
                    <td>Old Username</td>
                    <td>Changed</td>
                  </tr>
                </thead>
                <tbody>
                {usernameHistory.map((h,i) => (
                  <tr key={`uhist${i}`}>
                    <td>{h.oldUsername}</td>
                    <td>{formatDate(h.dateChanged)}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  )
}
export default UserModeration;
