import React, { useState, useEffect, useRef } from 'react';
import { Slider, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeUp, faVolumeMute, faTrash } from '@fortawesome/free-solid-svg-icons';

const CustomAudioPlayer = ({ src, title, onDelete, onLoadedMetadata }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('loadedmetadata', updateDuration);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleAudioError);
    return () => {
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('loadedmetadata', updateDuration);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleAudioError);
    };
  }, []);

  const updateTime = () => setCurrentTime(audioRef.current.currentTime);
  const updateDuration = () => setDuration(audioRef.current.duration);
  const handleEnded = () => setIsPlaying(false);
  const handleAudioError = (e) => console.error('Audio error:', e);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(e => console.error('Playback failed:', e));
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeChange = (_, newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };

  const handleVolumeChange = (_, newValue) => {
    audioRef.current.volume = newValue;
    setVolume(newValue);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={`custom-audio-player ${isMobile ? 'mobile' : ''}`}>
      <audio ref={audioRef} src={src} onLoadedMetadata={onLoadedMetadata} />
      <div className="title">{title}</div>
      <div className="flex-row">
        <div className="player-controls">
          <IconButton onClick={togglePlay} className="play-pause-btn">
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </IconButton>
          <div className="time-control">
            <span className="current-time">{formatTime(currentTime)}</span>
            <Slider
              value={currentTime}
              max={duration || 1}
              onChange={handleTimeChange}
              className="time-slider"
            />
            <span className="duration">{formatTime(duration)}</span>
          </div>
        </div>
        <div className="volume-control">
          <IconButton onClick={() => setVolume(volume === 0 ? 1 : 0)} className="volume-btn">
            <FontAwesomeIcon icon={volume === 0 ? faVolumeMute : faVolumeUp} />
          </IconButton>
          <Slider
            value={volume}
            max={1}
            step={0.01}
            onChange={handleVolumeChange}
            className="volume-slider"
          />
        </div>
        <IconButton onClick={onDelete} className="delete-btn">
          <FontAwesomeIcon icon={faTrash} />
        </IconButton>
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
