import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
import '../css/creator-dashboard.css';
import useAuth from '../hooks/useAuth';
import Card from '../components/card';
import Overlays from '../components/overlays';
import Controls from '../components/controls';
import SettingsForm from '../components/settings-form';
import OverlaySettings from '../components/overlay-settings';
import Personalization from '../components/personalization';
import Goal from '../components/goal';
import PaymentIntegrations from '../components/payment-integrations';
import Events from '../components/events';
import ExternalIntegrations from '../components/external-integrations';
import Community from '../components/community';
import Testing from '../components/testing';
import PowerchatPro from '../components/powerchat-pro';

const CreatorDashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleTab, setVisibleTab] = useState('Events');
  const [isLoading, setIsLoading] = useState(true);
  const { user, isMod} = useAuth();
  const [dashboardData, setDashboardData] = useState({});

  const userIsMod = isMod;

  const tabs = {
    overlays: 'Overlays',
    events: 'Events',
    controls: 'Controls',
    settings: 'TTS Settings',
    'powerchat-pro': (<span>Powerchat <b className="text-light-blue">Pro</b><sup>BETA</sup></span>),
    'overlay-settings': 'Overlay Settings',
    personalization: 'Personalization',
    goal: 'Goal',
    testing: 'Testing',
    'payment-integrations': 'Payment Integrations',
    'external-integrations': 'External Integrations',
    community: 'Community',
    'powerchat-page': 'Powerchat Page',
    'terms-of-service': 'Terms of Service',
    'privacy-policy': 'Privacy Policy',
  };

  const links = {
    'powerchat-page': '/' + user.username,
    'terms-of-service': '/terms-of-service',
    'privacy-policy': '/privacy-policy',
  };

  const formatLink = (link) => {
    const prepositions = ['and', 'of', 'in', 'on', 'at'];
    const formattedLink = link
      .split('-')
      .map((word) =>
        !prepositions.includes(word)
          ? word[0].toUpperCase() + word.slice(1)
          : word
      )
      .join(' ');
    return formattedLink;
  };

  const updateDashboardData = (newData) => {
    const newDashboardData = {
      ...dashboardData,
      ...newData,
    };
    setDashboardData(newDashboardData);
  };

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const { data } = await axios.get('/api/dashboard', {
          params: { username: user },
        });

        const twitchData = (await axios.get('/api/twitch-settings')).data;
        const trovoData = (await axios.get('/api/trovo-settings')).data;
        const kickData = (await axios.get('/api/kick-settings')).data;
        const cozyData = (await axios.get('/api/cozy-settings')).data;
        const dliveData = (await axios.get('/api/dlive-settings')).data;
        const xSettings = (await axios.get('/api/x-settings')).data;
        const youtubeSettings = (await axios.get('/api/youtube-settings')).data;
        const robotstreamerData = (await axios.get('/api/robotstreamer-settings')).data;
        const rumbleSettings = (await axios.get('/api/rumble-settings')).data;
        const overlayData = (await axios.get('/api/overlay-settings')).data;
        const goalSettings = (await axios.get('/api/goals')).data;
        const partiSettings = (await axios.get('/api/parti-settings')).data;

        setDashboardData({
          ...data,
          twitchSettings: twitchData,
          trovoSettings: trovoData,
          kickSettings: kickData,
          cozySettings: cozyData,
          dliveSettings: dliveData,
          xSettings,
          goalSettings,
          youtubeSettings,
          robotstreamerSettings: robotstreamerData,
          rumbleSettings,
          overlaySettings: overlayData,
          partiSettings,
        });

        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    getDashboardData();
  }, []);

  useEffect(() => {
    const loadTab = async () => {
      let urlParams = new URLSearchParams(window.location.search);
      let tabName = urlParams.get('tab');
      tabName = tabName ? tabName.toLowerCase() : 'overlays';
      selectTab(tabName.toLowerCase());
    };
    loadTab();
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const selectTab = (tab) => {
    setVisibleTab(tab);
    if (!links[tab]) {
      window.history.replaceState(null, null, '?tab=' + tab);
    }

    if (window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    ttsLimit,
    donationLimit,
    mediaLimit,
    extendedMediaLimit,
    mediaDuration,
    extendedMediaDuration,
    maxTtsSeconds,
    bannerUrl,
    tagLine,
    description,
    ttsImage,
    ttsSound,
    ttsVoice,
    subscribeImage,
    subscribeSound,
    subscribeMute,
    followImage,
    followSound,
    followMute,
    ttsFont,
    ttsAnimation,
    useDefaultProfanity,
    useDefaultMediaBlacklist,
    useDefaultChatFilter,
    blacklist,
    mediaBlacklist,
    mediaMinViews,
    mediaEnabled,
    showVideo,
    playTtsTitle,
    donatorSelectVoice,
    playbackEnabled,
    playbackDelay,
    ttsVolume,
    stripeUserId,
    stripeExpress,
    stripeEnabled,
    squareUserId,
    squareEnabled,
    paypalEmail,
    paypalScrape,
    paypalMerchantId,
    paypalEnabled,
    paypalLegacy,
    cryptoEnabled,
    cryptoAddress,
    cryptoBalance,
    cryptoInstantAlerts,
    cozytvEnabled,
    cozySettings,
    slAccessToken,
    robotstreamerSettings,
    twitchSettings,
    youtubeSettings,
    trovoSettings,
    kickSettings,
    rumbleSettings,
    dliveSettings,
    xSettings,
    goalSettings,
    community,
    twoFaSetup,
    coinName,
    coinCode,
    coingeckoId,
    overlaySettings,
    userLinks,
    customDonationAmounts,
    partiSettings,
  } = dashboardData;

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div className="page-container">
      <div className="creator-dashboard-container tabbed-cards-container">
        {windowWidth <= 600 && (
          <Drawer
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            className="drawer-container"
          >
            <IconButton
              onClick={handleDrawerToggle}
              className="drawer-toggle-button-open"
            >
              <ChevronLeftIcon />
            </IconButton>
            <List className="drawer-list">
              {Object.keys(tabs).map((tab, idx) => (
                <ListItem
                  button
                  key={tab + String(idx)}
                  onClick={() => {
                    selectTab(tab);
                    handleDrawerToggle();
                  }}
                  className={`tab ${tab === visibleTab ? 'active' : ''}`}
                >
                  {links[tab] ? (
                    <div>
                      <Link to={links[tab]}>{formatLink(tabs[tab])}</Link>
                      <img src="/static/img/arrow-forward-light-blue.webp" />
                    </div>
                  ) : (
                    <ListItemText primary={tabs[tab]} />
                  )}
                </ListItem>
              ))}
            </List>
          </Drawer>
        )}
        {windowWidth <= 600 && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className="drawer-toggle-button-closed"
            sx={{ ...(drawerOpen ? { display: 'none' } : {}) }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
        <div className="desktop-tab-menu tab-menu">
          {Object.keys(tabs).map((tab, idx) => (
            <div
              key={tab + String(idx)}
              onClick={() => selectTab(tab)}
              className={`tab ${tab === visibleTab ? 'active' : ''} ${
                tab === 'overlays'
                  ? 'overlays-border'
                  : tab === 'privacy-policy'
                  ? 'privacy-border'
                  : ''
              }`}
            >
              {links[tab] ? (
                <div>
                  <Link to={links[tab]}>{formatLink(tab)}</Link>
                  <img src="/static/img/arrow-forward-light-blue.webp" />
                </div>
              ) : (
                <div>{tabs[tab]}</div>
              )}
            </div>
          ))}
        </div>

        {visibleTab === 'overlays' && (
          <Card title="Overlays" lineClass="overlays-line">
            <Overlays />
          </Card>
        )}
        {visibleTab === 'events' && (
          <Card title="Events" lineClass="events-line">
            <Events username={user.username} />
          </Card>
        )}
        {visibleTab === 'controls' && (
          <Card title="Controls" lineClass="controls-line">
            <Controls
              mediaEnabled={mediaEnabled}
              playbackEnabled={playbackEnabled}
              ttsVolume={ttsVolume}
              playbackDelay={playbackDelay}
              ttsChatsMode={overlaySettings.ttsChatsMode}
              ttsChatsVolume={overlaySettings.ttsChatsVolume}
              ttsHeartbeat={overlaySettings.ttsHeartbeat}
              updateData={updateDashboardData}
              user={user}
            />
          </Card>
        )}
        {visibleTab === 'settings' && (
          <Card title="TTS Settings" lineClass="settings-line">
            <SettingsForm
              donationLimit={donationLimit}
              ttsLimit={ttsLimit}
              mediaLimit={mediaLimit}
              extendedMediaLimit={extendedMediaLimit}
              mediaDuration={mediaDuration}
              extendedMediaDuration={extendedMediaDuration}
              maxTtsSeconds={maxTtsSeconds}
              customDonationAmounts={customDonationAmounts}
              ttsImage={ttsImage}
              ttsSound={ttsSound}
              subscribeImage={subscribeImage}
              subscribeSound={subscribeSound}
              subscribeMute={subscribeMute}
              followImage={followImage}
              followSound={followSound}
              followMute={followMute}
              ttsVoice={ttsVoice}
              ttsFont={ttsFont}
              ttsAnimation={ttsAnimation}
              useDefaultProfanity={useDefaultProfanity}
              useDefaultMediaBlacklist={useDefaultMediaBlacklist}
              useDefaultChatFilter={useDefaultChatFilter}
              blacklist={blacklist}
              mediaBlacklist={mediaBlacklist}
              mediaMinViews={mediaMinViews}
              mediaEnabled={mediaEnabled}
              showVideo={showVideo}
              playTtsTitle={playTtsTitle}
              donatorSelectVoice={donatorSelectVoice}
              ttsDefaultColors={overlaySettings.ttsDefaultColors}
              ttsSubscriberColor={overlaySettings.ttsSubscriberColor}
              ttsDonatorColor={overlaySettings.ttsDonatorColor}
              ttsMessageColor={overlaySettings.ttsMessageColor}
              updateData={updateDashboardData}
            />
          </Card>
        )}
        {visibleTab === 'powerchat-pro' && (
          <Card title={<div>Powerchat Pro<sup>BETA</sup></div>} lineClass="powerchat-pro-line">
            <PowerchatPro />
          </Card>
        )}
        {visibleTab === 'overlay-settings' && (
          <Card title="Overlay Settings" lineClass="overlay-settings-line">
            <OverlaySettings
              viewCountOverlayOrientation={overlaySettings.viewCountOverlayOrientation}
              viewCountOrientation={overlaySettings.viewCountOrientation}
              viewCountPowerchatViewers={overlaySettings.viewCountPowerchatViewers}
              emoteWallAnimation={overlaySettings.emoteWallAnimation}
              emoteWallMaxEmoteSize={overlaySettings.emoteWallMaxEmoteSize}
              emoteWallSpeed={overlaySettings.emoteWallSpeed}
              chatOverlayTtl={overlaySettings.chatOverlayTtl}
              chatOverlayEmoteSize={overlaySettings.chatOverlayEmoteSize}
              chatOverlayMessageColor={overlaySettings.chatOverlayMessageColor}
              chatOverlayMessageColorRandom={overlaySettings.chatOverlayMessageColorRandom}
              chatOverlayChatterColor={overlaySettings.chatOverlayChatterColor}
              chatOverlayChatterColorRandom={overlaySettings.chatOverlayChatterColorRandom}
              chatOverlayChatterColorDefault={overlaySettings.chatOverlayChatterColorDefault}
              chatOverlayEmotesOnly={overlaySettings.chatOverlayEmotesOnly}
              chatOverlayNoEmotes={overlaySettings.chatOverlayNoEmotes}
              chatOverlayExpirationAnimation={overlaySettings.chatOverlayExpirationAnimation}
              chatOverlayIncomingAnimation={overlaySettings.chatOverlayIncomingAnimation}
              chatOverlayNoBadges={overlaySettings.chatOverlayNoBadges}
              chatOverlayNoAvatars={overlaySettings.chatOverlayNoAvatars}
              chatOverlayNoUsernames={overlaySettings.chatOverlayNoUsernames}
              chatOverlayTheme={overlaySettings.chatOverlayTheme}
              chatOverlayOrientation={overlaySettings.chatOverlayOrientation}
              chatOverlayFont={overlaySettings.chatOverlayFont}
              chatOverlayFontSize={overlaySettings.chatOverlayFontSize}
              chatOverlayBackgroundColor={overlaySettings.chatOverlayBackgroundColor}
              chatOverlayBackgroundTitleColor={overlaySettings.chatOverlayBackgroundTitleColor}
              chatOverlayBackgroundMessageColor={overlaySettings.chatOverlayBackgroundMessageColor}
              ttsChatsMode={overlaySettings.ttsChatsMode}
              hideChats={overlaySettings.hideChats}
              updateData={updateDashboardData}
              username={user.username}
            />
          </Card>
        )}
        {visibleTab === 'personalization' && (
          <Card title="Personalization" lineClass="personalization-line">
            <Personalization
              bannerUrl={bannerUrl}
              tagLine={tagLine}
              description={description}
              userLinks={userLinks}
              displayName={user.displayName}
              updateData={updateDashboardData}
            />
          </Card>
        )}
        {visibleTab === 'goal' && (
          <Card title="Goals" lineClass="goal-line">
            <Goal
              goalSettings={goalSettings}
              updateData={updateDashboardData}
            />
          </Card>
        )}
        {visibleTab === 'testing' && (
          <Card title="Testing" lineClass="testing-line">
            <Testing testViewCounts={overlaySettings.testViewCounts} />
          </Card>
        )}
        {visibleTab === 'payment-integrations' && (
          <Card
            title="Payment Integrations"
            lineClass="payment-integrations-line"
          >
            <PaymentIntegrations
              username={user}
              stripeUserId={stripeUserId}
              stripeEnabled={stripeEnabled}
              stripeExpress={stripeExpress}
              squareUserId={squareUserId}
              squareEnabled={squareEnabled}
              paypalEmail={paypalEmail}
              paypalEnabled={paypalEnabled}
              paypalLegacy={paypalLegacy}
              paypalMerchantId={paypalMerchantId}
              paypalScrape={paypalScrape}
              cryptoEnabled={cryptoEnabled}
              cryptoAddress={cryptoAddress}
              cryptoBalance={cryptoBalance}
              cryptoInstantAlerts={cryptoInstantAlerts}
              coinName={coinName}
              coinCode={coinCode}
              coingeckoId={coingeckoId}
              updateData={updateDashboardData}
              twoFaSetup={twoFaSetup}
            />
          </Card>
        )}
        {visibleTab === 'external-integrations' && (
          <ExternalIntegrations
            userIsMod={userIsMod}
            robotstreamerSettings={robotstreamerSettings}
            cozytvEnabled={cozytvEnabled}
            cozySettings={cozySettings}
            twitchSettings={twitchSettings}
            youtubeSettings={youtubeSettings}
            trovoSettings={trovoSettings}
            kickSettings={kickSettings}
            rumbleSettings={rumbleSettings}
            dliveSettings={dliveSettings}
            xSettings={xSettings}
            slAccessToken={slAccessToken}
            overlaySettings={overlaySettings}
            partiSettings={partiSettings}
            updateData={updateDashboardData}
          />
        )}
        {visibleTab === 'community' && (
          <Card title="Community" lineClass="community-line">
            <Community community={community} />
          </Card>
        )}
      </div>
    </div>
  );
};

export default CreatorDashboard;
