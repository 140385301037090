import React, { useState } from 'react';
import '../css/overlays.css';
import useAuth from '../hooks/useAuth';
import CopyButton from './copy-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import LinkOverlay from '../pages/link-overlay';
import QrOverlay from '../pages/qr-overlay';
import domToImage from 'dom-to-image';

const Overlays = () => {
  const { user } = useAuth();
  const baseURL = `${process.env.BASE_URL}/${user.username}`;
  const overlayLinks = [
    {
      overlay: 'TTS/Media Overlay',
      link: baseURL + '/tts',
    },
    {
      overlay: 'Event Overlay',
      link: baseURL + '/events',
    },
    {
      overlay: 'Goal Overlay',
      link: baseURL + '/goal',
    },
    {
      overlay: 'Subscription Goal Overlay',
      link: baseURL + '/subgoal',
    },
    {
      overlay: 'Follow Goal Overlay',
      link: baseURL + '/followgoal',
    },
    {
      overlay: 'Chat Overlay',
      link: baseURL + '/chat',
    },
    {
      overlay: 'View Count Overlay',
      link: baseURL + '/view-count',
    },
    {
      overlay: 'Emote Wall Overlay',
      link: baseURL + '/emote-wall',
    },
    {
      overlay: 'Subathon Overlay',
      link: baseURL + '/subathon',
    },
    {
      overlay: 'QR Code Overlay',
      link: baseURL + '/qr',
      screenshotName: 'powerchat-qrcode',
      Component: QrOverlay,
    },
    {
      overlay: 'Link Overlay',
      link: baseURL + '/link',
      screenshotName: 'powerchat-link',
      Component: LinkOverlay,
    },
  ];
  const [dropdowns, setDropdowns] = useState(Array(overlayLinks.length).fill(false));

  const toggleDropdown = (index) => {
    setDropdowns(dropdowns.map((d, i) => i == index ? !d : d));
  };

  const downloadScreenshot = async (screenshotName) => {
    screenshotPage(screenshotName);
  };

  const screenshotPage = async (id) => {
    let canvas = document.getElementById(id);
    let url = await domToImage.toPng(canvas);
    downloadImage(url, id + '.png');
  };

  const downloadImage = (image, fileName) => {
    const link = document.createElement('a');
    link.href = image;
    link.download = fileName;
    link.click();
  };

  return (
    <div className="overlays">
      <div className="overlay-links">
        {overlayLinks.map(({ overlay, link, screenshotName, Component }, idx) => (
          <div className="link-container" key={overlay + String(idx)}>
            <div className="link">
              <h4>{overlay + ':'}</h4>
            </div>
            <div className="flex-row">
              <CopyButton text={link} />
              <a href={link}>{link}</a>
            </div>
            {screenshotName && (
              <div className={"overlay-preview " + (dropdowns[idx] === true ? "expanded" : "")}>
                <button className="dropdown-btn" onClick={() => toggleDropdown(idx)}>
                  <FontAwesomeIcon icon={dropdowns[idx] === true ? faCaretUp : faCaretDown}
                    size="lg" color="#5bc7ec" />
                  &emsp;Preview
                </button>
                {dropdowns[idx] === true && (
                  <div>
                    <Component id={screenshotName} username={user.username} />
                    <button className="download-btn"
                      onClick={() => downloadScreenshot(screenshotName)}>
                      <FontAwesomeIcon icon={faDownload} size="1.5x" color="#5bc7ec" />
                      &nbsp;Download Copy
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Overlays;
