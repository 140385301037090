import React from 'react';
import ToggleSwitch from './toggle-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CurrencyInput from 'react-currency-input-field';

const CategorizedOptions = ({ formData, handleToggle, handleChange, toggleTooltip, tooltip, isMod, platform }) => {
  const platformOptions = {
    youtube: {
      'TTS/Media Overlay': [
        { name: 'enableYoutubeAllAlerts', label: 'Enable Alerts' },
        { name: 'enableSubscribeAlerts', label: 'Enable Subscribe Alerts' },
        { name: 'enableMemberAlerts', label: 'Enable Member Join Alerts' },
        { name: 'enableSuperchats', label: 'Enable Superchats' },
        { name: 'showOnYoutube', label: 'Show \'on YouTube\' in alerts', tooltip: 'Suffixes all alerts with \'on YouTube\', e.g. \'Bob just subscribed on YouTube\'' },
      ],
      'Goals Overlay': [
        { name: 'subscribersAddToGoal', label: 'Subscribers Add to Goal' },
        { name: 'membersAddToGoal', label: 'Members Add to Goal' },
        { name: 'superchatsAddToGoal', label: 'Superchats Add to Goal' },
      ],
      'Chat Overlay': [
        { name: 'enableYoutubeChats', label: 'Enable YouTube Chats' },
      ],
      'View Count Overlay': [
        { name: 'viewCountYoutubeViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your YouTube stream.\n\nNote: You can adjust how the view count overlay looks in the Settings page.' },
      ],
    },
    twitch: {
      'TTS/Media Overlay': [
        { name: 'enableTwitchAllAlerts', label: 'Enable All Alerts' },
        { name: 'enableTwitchBits', label: 'Enable Bits Alerts' },
        { name: 'playTwitchBitsTts', label: 'Play Bits message with TTS' },
        { name: 'showOnTwitch', label: 'Show \'on Twitch\' in alerts', tooltip: 'Suffixes all alerts with \'on Twitch\', e.g. \'Bob just subscribed on Twitch\'' },
        { name: 'enableTwitchFollowAlerts', label: 'Enable Follow Alerts' },
        { name: 'enableTwitchSubscribeAlerts', label: 'Enable Subscribe Alerts' },
        { name: 'enableTwitchRaidAlerts', label: 'Enable Raid Alerts' },
      ],
      'Goals Overlay': [
        { name: 'twitchFollowsAddToGoal', label: 'Followers Add to Goal' },
        { name: 'twitchSubsAddToGoal', label: 'Subscribers Add to Goal' },
        { name: 'twitchBitsAddToGoal', label: 'Bits Add to Goal' },
      ],
      'Chat Overlay': [
        { name: 'enableTwitchChats', label: 'Enable Twitch Chats' },
      ],
      'View Count Overlay': [
        { name: 'viewCountTwitchViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your Twitch stream.\n\nNote: You can adjust how the view count overlay looks in the Settings page.' },
      ],
    },
    trovo: {
      'TTS/Media Overlay': [
        { name: 'enableAllAlerts', label: 'Enable Alerts' },
        { name: 'showOnTrovo', label: 'Show \'on Trovo\' in alerts', tooltip: 'Suffixes all alerts with \'on Trovo\', e.g. \'Bob cast Stay Safe on Trovo\', \'Alice just subscribed on Trovo\'' },
        { name: 'enableFollowAlerts', label: 'Enable Follow Alerts', dependsOn: 'enableAllAlerts' },
        { name: 'enableSubscribeAlerts', label: 'Enable Subscribe Alerts', dependsOn: 'enableAllAlerts' },
        { name: 'enableManaSpells', label: 'Enable Mana Alerts', dependsOn: 'enableAllAlerts' },
        { name: 'playManaTts', label: 'Play Mana message with TTS', dependsOn: 'enableManaSpells' },
        { name: 'enableElixirSpells', label: 'Enable Elixir Alerts', dependsOn: 'enableAllAlerts' },
        { name: 'playElixirTts', label: 'Play Elixir message with TTS', dependsOn: 'enableElixirSpells' },
      ],
      'Goals Overlay': [
        { name: 'followsAddToGoal', label: 'Followers Add to Goal', tooltip: 'Trovo follows will increment the follower goal. Follow alerts do not need to be enabled.' },
        { name: 'subsAddToGoal', label: 'Subscribers Add to Goal', tooltip: 'New Trovo subscribers will increment the subscription goal. Subscribe alerts do not need to be enabled.' },
        { name: 'elixirAddsToGoal', label: 'Elixir Adds to Goal', tooltip: 'Dollar amount of Elixir spells will be added to donation goal. Elixir alerts do not need to be enabled.' },
      ],
      'Chat Overlay': [
        { name: 'enableTrovoChats', label: 'Enable Trovo Chats' },
      ],
      'View Count Overlay': [
        { name: 'viewCountTrovoViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your Trovo stream.\n\nNote: You can adjust how the view count overlay looks in the Settings page.' },
      ],
    },
    rumble: {
        'TTS/Media Overlay': [
            { name: 'enableAllRumbleAlerts', label: 'Enable All Rumble Alerts' },
            { name: 'enableRumbleRants', label: 'Enable Rumble Rants', dependsOn: 'enableAllRumbleAlerts' },
            { name: 'showOnRumble', label: 'Show \'on Rumble\' in alerts', tooltip: 'Suffixes all alerts with \'on Rumble\', e.g. \'Alice just subscribed on Rumble\'', dependsOn: 'enableAllRumbleAlerts' },
        ],
        'Goals Overlay': [
            { name: 'rantsAddToGoal', label: 'Rants Add to Goal', tooltip: 'Rumble Rants will increment the donation goal. Rant TTS does not need to be enabled.', dependsOn: 'enableAllRumbleAlerts' },
            { name: 'rumbleSubsAddToGoal', label: 'Subscribers Add to Goal', tooltip: 'Rumble subscribers will increment the subscription goal. Subscribe alerts do not need to be enabled.', dependsOn: 'enableAllRumbleAlerts' },
        ],
        'Chat Overlay': [
            { name: 'enableRumbleChats', label: 'Enable Rumble Chats' },
        ],
        'View Count Overlay': [
            { name: 'viewCountRumbleViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your Rumble stream.\n\nNote: You can adjust how the view count overlay looks in the Settings page.' },
        ],
        },
    kick: {
      'TTS/Media Overlay': [
        { name: 'enableKickAlerts', label: 'Enable Alerts' },
        { name: 'showOnKick', label: 'Show \'on Kick\' in alerts', tooltip: 'Suffixes all alerts with \'on Kick\', e.g. \'Bob followed on Kick\', \'Alice just subscribed on Kick\'' },
        { name: 'playKickTts', label: 'Play Alerts with TTS' },
        { name: 'enableKickHostAlerts', label: 'Enable Host Alerts' },
        { name: 'enableKickSubscribeAlerts', label: 'Enable Subscribe Alerts' },
      ],
      'Goals Overlay': [
        { name: 'kickSubsAddToGoal', label: 'Subscribers Add to Goal', tooltip: 'Kick subscribers will increment the subscription goal. Subscribe alerts do not need to be enabled.' },
      ],
      'Chat Overlay': [
        { name: 'enableKickChats', label: 'Enable Kick Chats' },
      ],
      'View Count Overlay': [
        { name: 'viewCountKickViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your Kick stream.\n\nNote: You can adjust how the view count overlay looks in the Settings page.' },
      ],
    },
    dlive: {
      'TTS/Media Overlay': [
        { name: 'enableAllDliveAlerts', label: 'All Enable Alerts' },
        { name: 'showOnDlive', label: 'Show \'on DLive\' in alerts', tooltip: 'Suffixes all alerts with \'on DLive\', e.g. \'Bob followed on DLive\', \'Alice just subscribed on DLive\'', dependsOn: 'enableAllDliveAlerts' },
        { name: 'playDliveLemonTts', label: 'Enable DLive Lemon TTS' },
        { name: 'enableDliveFollowAlerts', label: 'Enable Follow Alerts', dependsOn: 'enableAllDliveAlerts' },
        { name: 'enableDliveSubscribeAlerts', label: 'Enable Subscription Alerts', dependsOn: 'enableAllDliveAlerts' },
        { name: 'enableDliveLemonAlerts', label: 'Enable Lemon Alerts', dependsOn: 'enableAllDliveAlerts' },
        { name: 'enableDliveHostAlerts', label: 'Enable Host Alerts', dependsOn: 'enableAllDliveAlerts' },
      ],
      'Goals Overlay': [
        { name: 'dliveFollowsAddToGoal', label: 'Followers Add to Goal', tooltip: 'DLive followers will increment the follower goal. Follower alerts do not need to be enabled.', dependsOn: 'enableAllDliveAlerts' },
        { name: 'dliveSubsAddToGoal', label: 'Subscribers Add to Goal', tooltip: 'DLive subscribers will increment the subscription goal. Subscribe alerts do not need to be enabled.', dependsOn: 'enableAllDliveAlerts' },
        { name: 'dliveLemonsAddToGoal', label: 'Lemons Adds to Goal', tooltip: 'Dollar amount of lemons will be added to donation goal. Lemons alerts do not need to be enabled.', dependsOn: 'enableAllDliveAlerts' },
      ],
      'Chat Overlay': [
        { name: 'enableDliveChats', label: 'Enable DLive Chats' },
      ],
      'View Count Overlay': [
        { name: 'viewCountDliveViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your Dlive stream.\n\nNote: You can adjust how the view count overlay looks in the Settings page.' },
      ],
    },
    xcom: {
      'Chat Overlay': [
        { name: 'enableXChats', label: 'Enable X Chats' },
      ],
      'View Count Overlay': [
        { name: 'viewCountXViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of views on your X stream.\n\nNote: You can adjust how the view count overlay looks in the Overlay Settings page.' },
      ],
    },
    cozy: {
      'TTS/Media Overlay': [
        { name: 'enableAllCozyAlerts', label: 'Enable All Cozy Alerts' },
        { name: 'enableTtsAlerts', label: 'Enable Follow Alerts', dependsOn: 'enableAllCozyAlerts' },
        { name: 'onCozyTts', label: '"On Cozy" TTS', dependsOn: 'enableAllCozyAlerts' },
      ],
      'Goals Overlay': [
        { name: 'cozyFollowsAddToGoal', label: 'Followers Add to Goal', tooltip: 'Cozy follows will increment the follower goal. Follow alerts do not need to be enabled.', dependsOn: 'enableAllCozyAlerts' },
      ],
      'Chat Overlay': [
        { name: 'enableCozyChats', label: 'Enable Cozy Chats' },
      ],
      'View Count Overlay': [
        { name: 'viewCountCozyViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your Cozy stream.\n\nNote: You can adjust how the view count overlay looks in the Settings page.' },
      ],
    },
    robotstreamer: {
      'Chat Overlay': [
        { name: 'enableRobotstreamerChats', label: 'Enable Robotstreamer Chats' },
      ],
      'View Count Overlay': [
        { name: 'viewCountRobotstreamerViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your Robotstreamer stream.\n\nNote: You can adjust how the view count overlay looks in the Settings page.' },
      ],
    },
    parti: {
      'Chat Overlay': [
        { name: 'enablePartiChats', label: 'Enable Parti Chat' }
      ],
      'View Count Overlay': [
        { name: 'viewCountPartiViewers', label: 'Show in view count overlay', tooltip: 'When enabled, the view count overlay will show the number of viewers on your Parti stream.' }
      ]
    },
  };

  const options = platformOptions[platform];

  return (
    <div className="categorized-options">
    {options['TTS/Media Overlay'] && (
      <div className="column">
        <div className="category">
          <h5>TTS/Media Overlay</h5>
          {options['TTS/Media Overlay'].map((option) => (
            <div key={option.name} className="toggle-container">
              <ToggleSwitch
                name={option.name}
                onChange={() => handleToggle(option.name)}
                checked={formData[option.name]}
                disabled={isMod || (option.dependsOn && !formData[option.dependsOn])}
              />
              <label htmlFor={option.name}>{option.label}</label>
              {option.tooltip && (
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip(option.name)}
                  />
                  {tooltip === option.name && (
                    <p className="tooltip shadow-lg">{option.tooltip}</p>
                  )}
                </div>
              )}
            </div>
          ))}
          {platform === 'youtube' && (
            <React.Fragment>
              <label htmlFor="superchatMin">Superchat TTS Minimum</label>
              <div style={{ width: '250px' }}>
                <CurrencyInput
                  name="superchatMin"
                  placeholder="Superchat TTS Minimum"
                  value={formData.superchatMin || ''}
                  prefix="$"
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  allowNegativeValue={false}
                  decimalScale={2}
                  onValueChange={(value, name) =>
                    handleChange({ target: { value, name } })
                  }
                  disabled={isMod}
                />
              </div>
            </React.Fragment>
          )}
          {platform === 'twitch' && (
            <React.Fragment>
              <label htmlFor="playTwitchBitsMinimum">Bits TTS Minimum</label>
              <div style={{ width: '250px' }}>
                <input
                  name="playTwitchBitsMinimum"
                  placeholder="Bits TTS Minimum"
                  onChange={handleChange}
                  disabled={isMod}
                  value={formData.playTwitchBitsMinimum}
                />
              </div>
            </React.Fragment>
          )}
          {platform === 'dlive' && (
            <React.Fragment>
              <label htmlFor="playDliveLemonMinimum">Lemons TTS Minimum</label>
              <div style={{ width: '250px' }}>
                <input
                  name="playDliveLemonMinimum"
                  placeholder="Lemon TTS Minimum"
                  onChange={handleChange}
                  disabled={isMod}
                  value={formData.playDliveLemonMinimum}
                />
              </div>
            </React.Fragment>
          )}
          {platform === 'rumble' && (
            <React.Fragment>
              <label htmlFor="playRantsMinimum">Rants TTS Minimum</label>
              <div style={{ width: '250px' }}>
                <CurrencyInput
                  name="playRantsMinimum"
                  placeholder="Rants TTS Minimum"
                  value={formData.playRantsMinimum || ''}
                  prefix="$"
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  allowNegativeValue={false}
                  decimalScale={2}
                  onValueChange={(value, name) =>
                    handleChange({ target: { value, name } })
                  }
                  disabled={isMod}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )}
    {options['Goals Overlay'] && (
      <div className="column">
        <div className="category">
          <h5>Goals Overlay</h5>
          {options['Goals Overlay'].map((option) => (
            <div key={option.name} className="toggle-container">
              <ToggleSwitch
                name={option.name}
                onChange={() => handleToggle(option.name)}
                checked={formData[option.name]}
                disabled={isMod || (option.dependsOn && !formData[option.dependsOn])}
              />
              <label htmlFor={option.name}>{option.label}</label>
              {option.tooltip && (
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip(option.name)}
                  />
                  {tooltip === option.name && (
                    <p className="tooltip shadow-lg">{option.tooltip}</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )}
    {(options['Chat Overlay'] || options['View Count Overlay']) && (
      <div className={(!options['TTS/Media Overlay'] && !options['Goals Overlay']) ? "row" : "column"}>
      {options['Chat Overlay'] && (
        <div className="category">
          <h5>Chat Overlay</h5>
          {options['Chat Overlay'].map((option) => (
            <div key={option.name} className="toggle-container">
              <ToggleSwitch
                name={option.name}
                onChange={() => handleToggle(option.name)}
                checked={formData[option.name]}
                disabled={isMod || (option.dependsOn && !formData[option.dependsOn])}
              />
              <label htmlFor={option.name}>{option.label}</label>
              {option.tooltip && (
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip(option.name)}
                  />
                  {tooltip === option.name && (
                    <p className="tooltip shadow-lg">{option.tooltip}</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {options['View Count Overlay'] && (
        <div className="category">
          <h5>View Count Overlay</h5>
          {options['View Count Overlay'].map((option) => (
            <div key={option.name} className="toggle-container">
              <ToggleSwitch
                name={option.name}
                onChange={() => handleToggle(option.name)}
                checked={formData[option.name]}
                disabled={isMod || (option.dependsOn && !formData[option.dependsOn])}
              />
              <label htmlFor={option.name}>{option.label}</label>
              {option.tooltip && (
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip(option.name)}
                  />
                  {tooltip === option.name && (
                    <p className="tooltip shadow-lg">{option.tooltip}</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      </div>
    )}
    </div>
  );
};

export default CategorizedOptions;
