import React, { useState, useEffect } from 'react';
import Soundboard from './soundboard';
import '../css/controls.css';
import axios from 'axios';
import FormAlert from './form-alert';
import Slider from './slider';
import ToggleSwitch from './toggle-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import MuiSlider from '@mui/material/Slider';
import Box from '@mui/material/Box';

const Controls = ({ updateData, user, isMod = false, ...props }) => {
  const initialFormState = { ...props };
  const [formData, setFormData] = useState(initialFormState);
  const muted = false;
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [tooltip, setTooltip] = useState('');

  const skipMessage = async () => {
    try {
      await axios.get('/api/skip');
      triggerAlert('Chat skipped');
    } catch (err) {
      console.error(err);
    }
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  const handleTTSModeChange = (event) => {
    setFormData({
      ...formData,
      ttsChatsMode: event.target.value,
    });
  };

  const handleSlider = (evt) => {
    let value = evt.target.valueAsNumber;
    if (evt.target.name == 'ttsVolume' || evt.target.name == 'ttsChatsVolume') {
      value = Math.round(value);
    }
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  const resetForm = () => {
    setFormData(initialFormState);
    triggerAlert('Changes discarded');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.put('/api/mediacontrols', formData);
      if (data === 'OK') {
        updateData(formData);
        triggerAlert('Control settings updated');
      } else {
        triggerAlert('Could not save controls', false);
      }
    } catch (err) {
      console.error(err);
      triggerAlert('Could not save controls', false);
    }
  };

  return (
    <div className="parent-container">
    <div className="controls-container form-container">
      <button disabled={isMod} className="skip-btn secondary-btn" onClick={skipMessage}>
        Skip Message
      </button>
      <form onSubmit={handleSubmit}>
        <div className="toggle-switch-row flex-form-row">
          <ToggleSwitch
            name="mediaEnabled"
            onChange={() => handleToggle('mediaEnabled')}
            checked={formData.mediaEnabled}
            disabled={isMod}
          />
          <label htmlFor="mediaEnabled">Media Enabled</label>
        </div>
        <div className="toggle-switch-row flex-form-row">
          <ToggleSwitch
            name="playbackEnabled"
            onChange={() => handleToggle('playbackEnabled')}
            checked={formData.playbackEnabled}
            disabled={isMod}
          />
          <label htmlFor="playbackEnabled">Playback Enabled</label>
        </div>
        <div>
          <Slider name="playbackDelay"
            label="Delay Between Playbacks"
            value={formData.playbackDelay}
            min={0}
            max={10}
            step={1}
            unit="s"
            handleChange={handleSlider}
            list="playbackDelayMarkers"
            disabled={isMod}
          />
          <datalist id="playbackDelayMarkers">
            {[...Array(11).keys()].map(i => (
              <option key={"playbackDelay" + i} value={i} />
            ))}
          </datalist>
        </div>
        <div>
          <Slider name="ttsVolume"
            label="Volume"
            min={0}
            max={100}
            step={0.2}
            unit="%"
            value={formData.ttsVolume}
            handleChange={handleSlider}
            disabled={muted || isMod}
          />
        </div>
        <div className="toggle-switch-row flex-form-row mui-slider">
          <Box sx={{ width: 150, margin: "10px 5px 0px 5px" }}>
            <MuiSlider
              step={1}
              track={false}
              min={0}
              max={2}
              marks={[
                {
                  value: 0,
                  label: 'Off',
                },
                {
                  value: 1,
                  label: 'Command (subs only)',
                },
                {
                  value: 2,
                  label: 'All',
                },
              ]}
              valueLabelDisplay="off"
              value={formData.ttsChatsMode}
              onChange={handleTTSModeChange}
              name="ttsChatsMode"
              disabled={isMod}
            />
          </Box>
          <label style={{ marginBottom: 12, marginLeft: 14 }}>Live chat TTS mode</label>
          <div style={{ marginBottom: 12 }} className="icon-btn tooltip-wrapper">
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="#57bad8"
              onClick={() => toggleTooltip('ttsChats')}
            />
            {tooltip === 'ttsChats' && (
              <p className="tooltip left shadow-lg">
                Off: TTS is disabled.<br/>
                Command: Sub-only mode. Activated AI voices (e.g., !joey) are used.<br/>
                All: Every message is read with the default TTS. Subs can use specific voice commands.
              </p>
            )}
          </div>
        </div>
        <div>
          <Slider name="ttsChatsVolume"
            label="Live Chat TTS Volume"
            min={0}
            max={100}
            step={10}
            unit="%"
            value={formData.ttsChatsVolume}
            handleChange={handleSlider}
            disabled={formData.ttsChatsMode == 0 || isMod}
            list="chatsVolumeMarkers" />
          <datalist id="chatsVolumeMarkers">
            {[...Array(11).keys()].map(i => (
              <option key={"chatVolume" + i} value={10*i} />
            ))}
          </datalist>
        </div>
        <div className="toggle-switch-row flex-form-row">
          <ToggleSwitch
            name="ttsHeartbeat"
            onChange={() => handleToggle('ttsHeartbeat')}
            checked={formData.ttsHeartbeat}
            disabled={isMod}
          />
          <label htmlFor="ttsHeartbeat">Enable TTS Keepalive Sound</label>
          <div className="icon-btn tooltip-wrapper">
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="#57bad8"
              onClick={() => toggleTooltip('ttsHeartbeat')}
            />
            {tooltip === 'ttsHeartbeat' && (
              <p className="tooltip shadow-lg">
                For bluetooth speakers. Plays a near-inaudible note on TTS overlay 
                every five minutes, to keep connections alive.
              </p>
            )}
          </div>
        </div>
      {!isMod && (
        <div>
          <button type="submit">Save</button>
          <button
            type="reset"
            className="secondary-btn cancel-btn"
            onClick={resetForm}
          >
            Cancel
          </button>
        </div>
      )}
      </form>
      <FormAlert alertState={alertState} width={400} />
    </div>
    <Soundboard user={user} isMod={isMod} />
    </div>
  );
};

export default Controls;
