import React, { useState, useRef } from 'react';
import '../css/copy-button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

const CopyButton = ({ text, display = null, size = "2x", title = null, onClick = () => {} }) => {
  const [successTooltipIsVisible, setSuccessTooltipIsVisible] = useState(false);
  const [successTooltipIsHidden, setSuccessTooltipIsHidden] = useState(true);

  const hiddenTimeout = useRef(null);
  
  const copyText = () => {
    if (onClick) {
      onClick();
    }

    if (!successTooltipIsVisible) {
      if (typeof(text) == 'function') {
        navigator.clipboard.writeText(text());
      } else {
        navigator.clipboard.writeText(text);
      }
      setSuccessTooltipIsVisible(true);
      setSuccessTooltipIsHidden(false);
      setTimeout(() => {
        setSuccessTooltipIsVisible(false);
      }, 500);

      if (hiddenTimeout.current) {
        clearTimeout(hiddenTimeout.current);
      }
      hiddenTimeout.current = setTimeout(() => {
        setSuccessTooltipIsHidden(true);
      }, 2000);
    }
  };

  return (
    <div className="copy-btn-container" onClick={copyText} title={title}>
      <button type="button">
      { size[0] != '0' && (
        <FontAwesomeIcon
          icon={faCopy}
          color="gray"
          size={size}
          title="Copy"
        />
      )}
      {display && <a>{display}</a>}
      </button>
      <p
        className={
          'tooltip shadow-lg' + (!successTooltipIsVisible ? ' invisible' : '')
          + (successTooltipIsHidden ? ' hidden' : '')
        }
      >
        Copied
      </p>
    </div>
  );
};

export default CopyButton;
