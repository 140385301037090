import React from 'react';
import '../css/slider.css';

const Slider = ({ name, label, unit, value, disabled, handleChange, list,
  step = 1, min = 0, max = 100, width, hideLabel=false, hideValue=false }) => {
  const sliderStyle = width ? { width } : {};
  const inputStyle = width ? { width: '100%' } : {};
  return (
    <div id={`${name}Slider`} className="slider-container" style={sliderStyle}>
      {!hideLabel || !hideValue ? (
        <div className="slider-label">
          {!hideLabel && <h4>{label}:</h4>}
          {!hideValue && (
            <div className="value-container">
              <p>{value}{unit}</p>
            </div>
          )}
        </div>
      ) : null}
      <div>
        <input
          type="range"
          name={name}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          list={list}
          style={inputStyle}
        />
      </div>
    </div>
  );
};

export default Slider;
