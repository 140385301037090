import React, { useState, useEffect } from 'react';
import { Button, TextField, MenuItem, FormGroup, FormControlLabel, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { voiceCategories } from './voice-categories';
import CopyButton from './copy-button';

const VoiceCard = ({ voice, type, listId, onRemove, onActivate, onDeactivate, canDeactivate, isEditing, onSave, onCancel, disabled, eventEmitter }) => {
  const category = voice.category || 'Default';
  const sampleUrl = voice.sampleUrl.startsWith('https://') ? `/audioproxy?url=${voice.sampleUrl}` : voice.sampleUrl;
  const { icon, color } = voiceCategories[category] || voiceCategories.Default;
  const [editedName, setEditedName] = useState(voice.name || '');
  const [editedCategory, setEditedCategory] = useState(category);
  const [editedCommand, setEditedCommand] = useState(voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`);
  const [permissions, setPermissions] = useState(voice.permissions || {
    all: true,
    vip: false,
    sub: false,
    mod: false
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(null);

  eventEmitter.on('playing', (message) => {
    if (message.voiceId != voice.id) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  });

  useEffect(() => {
    if (isEditing) {
      setEditedName(voice.name || '');
      setEditedCategory(category);
      setEditedCommand(voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`);
      setPermissions(voice.permissions || {
        all: true,
        vip: false,
        sub: false,
        mod: false
      });
    }
  }, [isEditing, voice, category]);

  const handleSave = () => {
    onSave({ ...voice, name: editedName, category: editedCategory, command: editedCommand, permissions, type });
  };

  const handlePermissionChange = (event) => {
    setPermissions({ ...permissions, [event.target.name]: event.target.checked });
  };

  const truncateName = (name) => {
    if (name.length > 30) {
      return name.substring(0, 28) + '..';
    }
    return name;
  };

  const truncateCommand = (command) => {
    if (command.length > 25) {
      return command.substring(0, 23) + '..';
    }
    return command;
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      eventEmitter.emit('playing', { voiceId: voice.id });
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className={`voice-card ${type} ${voice.active ? 'active' : ''} ${isEditing ? 'editing' : ''}`} data-list-id={listId}>
      <div className="voice-icon" style={{ backgroundColor: color }}>
        <FontAwesomeIcon icon={icon} size="xs" />
      </div>
      <div className="voice-flair">{category}</div>
      {!isEditing && (
        <React.Fragment>
          <div className="voice-info">
            <p className="voice-name">{truncateName(voice.name)}</p>
            {type === 'my' && (
              <Button
                variant="contained"
                className="voice-delete-btn"
                onClick={onRemove}
                color="error"
                size="small"
              >
                <FontAwesomeIcon icon={faTimes} className="times-icon" /> 
              </Button>
            )}
          </div>
        {sampleUrl && (
          <div className="voice-sample-player">
            <div
              className={`custom-play-button ${isPlaying ? 'playing' : ''}`}
              onClick={togglePlay}
            />
            <audio crossOrigin="anonymous" hidden controls
              ref={audioRef}
              src={sampleUrl}
              disabled={disabled}
              onEnded={handleAudioEnded}>
            </audio>
          </div>
        )}
          <div className="voice-command-container">
            <p className="voice-command-label">Command:</p>
            <CopyButton text={voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`}
              display={truncateCommand(voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`)}
              size="1x"
              title="Click to copy"
            />
          </div>
        </React.Fragment>
      )}
      {isEditing && (
        <React.Fragment>
          {type === 'my' && (
            <React.Fragment>
              <TextField
                label="Name"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                fullWidth
                size="small"
                className="edit-field"
              />
              <TextField
                label="Category"
                value={editedCategory}
                onChange={(e) => setEditedCategory(e.target.value)}
                fullWidth
                size="small"
                select
                className="edit-field"
              >
                {Object.keys(voiceCategories).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Command"
                value={editedCommand}
                onChange={(e) => setEditedCommand(e.target.value)}
                fullWidth
                size="small"
                className="edit-field"
              />
            </React.Fragment>
          )}
          <Typography variant="subtitle2" className="permissions-title">Permissions:</Typography>
          <FormGroup className={`permissions-group ${type !== 'my' ? 'larger-permissions' : ''}`}>
            <Grid container spacing={1}>
              {['all', 'vip', 'sub', 'mod'].map((perm) => (
                <Grid item xs={6} key={perm}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={permissions[perm]} 
                        onChange={handlePermissionChange} 
                        name={perm} 
                        size={type !== 'my' ? 'medium' : 'small'}
                      />
                    }
                    label={perm.charAt(0).toUpperCase() + perm.slice(1)}
                    className={`permission-label ${type !== 'my' ? 'larger-label' : ''}`}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </React.Fragment>
      )}
      <div className="voice-actions">
        {isEditing ? (
          <div className="edit-actions">
            <IconButton
              className="save-btn"
              onClick={handleSave}
              color="primary"
              size="small"
            >
              <FontAwesomeIcon icon={faCheck} />
            </IconButton>
            <IconButton
              className="cancel-btn"
              onClick={onCancel}
              color="error"
              size="small"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </div>
        ) : (
          <React.Fragment>
            <React.Fragment>
            {voice.active ? (
              <React.Fragment>
                <Button
                  variant="contained"
                  className="deactivate-btn"
                  onClick={onDeactivate}
                  disabled={!canDeactivate}
                  fullWidth
                  color="error"
                  size="small"
                >
                  DEACTIVATE
                 </Button>
              </React.Fragment>
            ) : (
              <Button
                variant="contained"
                className={"activate-btn" + (disabled ? ' disabled' : '')}
                onClick={disabled ? () => alert('This voice is only available to Powerchat Pro Streamer subscribers') : onActivate}
                fullWidth
                color="primary"
                size="small"
              >
                ACTIVATE
              </Button>
            )}
            </React.Fragment>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default VoiceCard;
