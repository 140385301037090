import React, { useEffect } from 'react';
import '../css/time-picker.css';

const TimePicker = ({ onChange, time, disabled = false, name = null }) => { 
  const handleTimerChange = (event) => {
    let value = event.target.type == 'number' ? Number(event.target.value) : event.target.value;
    onChange(event, {
      ...time,
      [event.target.id]: value,
    });
  };

  const setTime = (t) => {
    onChange({ target: { name }}, t);
  };

  useEffect(() => {
    if (time.hours < 0) {
      setTime({
        ...time,
        hours: 0,
      });
    }
  }, [time.hours]);

  useEffect(() => {
    if (time.minutes >= 60) {
      setTime({
        ...time,
        minutes: time.minutes % 60,
        hours: time.hours + Math.floor(time.minutes / 60),
      });
    }
    else if (time.minutes < 0 && time.hours <= 0) {
      setTime({
        ...time,
        minutes: 0,
        hours: 0,
      });
    }
    else if (time.minutes < 0) {
      setTime({
        ...time,
        minutes: (time.minutes + 60) % 60,
        hours: time.hours + Math.floor(time.minutes / 60),
      });
    }
  }, [time.minutes]);

  useEffect(() => {
    if (time.seconds >= 60) {
      setTime({
        ...time,
        seconds: time.seconds % 60,
        minutes: time.minutes + Math.floor(time.seconds / 60),
      });
    }
    else if (time.seconds < 0 && time.minutes <= 0 && time.hours <= 0) {
      setTime({
        ...time,
        seconds: 0,
        minutes: 0,
        hours: 0,
      });
    }
    else if (time.seconds < 0) {
      setTime({
        ...time,
        seconds: (time.seconds + 60) % 60,
        minutes: time.minutes + Math.floor(time.seconds / 60),
      });
    }
  }, [time.seconds]);

  return (
    <div className={"time-picker " + (disabled ? 'disabled' : '')}>
      <input type="number" name={name} id="hours" onChange={handleTimerChange} disabled={disabled}
        onFocus={((e) => e.target.select())}
        value={String(time.hours).padStart(2, '0')} />
      &#x3A;
      <input type="number" max={61} min={-1} name={name} id="minutes" onChange={handleTimerChange} disabled={disabled}
        onFocus={((e) => e.target.select())}
        value={String(time.minutes).padStart(2, '0')} />
      &#x3A;
      <input type="number" max={61} min={-1} name={name} id="seconds" onChange={handleTimerChange} disabled={disabled}
        onFocus={((e) => e.target.select())}
        value={String(time.seconds).padStart(2, '0')} />
    </div>
  );
};

export default TimePicker;
