import React, { useEffect, useState } from 'react';
import '../css/network.css';
import useAuth from '../hooks/useAuth';
import PageError from '../pages/page-error';
import axios from 'axios';

const LiveNowNetwork = () => {
  const baseURL = `${process.env.BASE_URL}`;
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({
    liveUsers: [],
    offlineUsers: [],
    shownLiveUsers: [],
    shownOfflineUsers: []
  });
  const [error, setError] = useState(null);
  const { isAdmin } = useAuth();
  const numberOfLiveUsersToShowInitially = 10;
  const numberOfOfflineUsersToShowInitially = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/pubapi/live-network`);
        const data = response.data;
        const live = data.filter(user => user.isLive).sort((a, b) => b.totalViewers - a.totalViewers);
        const offline = data.filter(user => !user.isLive).sort((a, b) => a.username.localeCompare(b.username));

        setUserData({
          liveUsers: live,
          offlineUsers: offline,
          shownLiveUsers: live.slice(0, numberOfLiveUsersToShowInitially),
          shownOfflineUsers: offline.slice(0, numberOfOfflineUsersToShowInitially)
        });
      } catch (err) {
        console.error(err);
        setError(err.message || 'Error fetching data');
      } finally {
        setIsLoading(false);
      }
    };

    if (isAdmin) {
      fetchData();
      const interval = setInterval(fetchData, 60000);
      return () => clearInterval(interval);
    }
  }, [isAdmin, baseURL, numberOfLiveUsersToShowInitially, numberOfOfflineUsersToShowInitially]);

  useEffect(() => {
    console.info(`Shown Live Users: ${userData.shownLiveUsers.length} / ${userData.liveUsers.length}`);
    console.info(`Shown Offline Users: ${userData.shownOfflineUsers.length} / ${userData.offlineUsers.length}`);
    console.info(``);
  }, [userData]);

  const handleShowMoreLive = () => {
    setUserData(prev => ({
      ...prev,
      shownLiveUsers: prev.liveUsers.slice(0, prev.shownLiveUsers.length + numberOfLiveUsersToShowInitially)
    }));
  };

  const handleShowLessLive = () => {
    setUserData(prev => ({
      ...prev,
      shownLiveUsers: prev.liveUsers.slice(0, numberOfLiveUsersToShowInitially)
    }));
  };

  const handleShowMoreOffline = () => {
    setUserData(prev => ({
      ...prev,
      shownOfflineUsers: prev.offlineUsers.slice(0, prev.shownOfflineUsers.length + numberOfOfflineUsersToShowInitially)
    }));
  };

  const handleShowLessOffline = () => {
    setUserData(prev => ({
      ...prev,
      shownOfflineUsers: prev.offlineUsers.slice(0, numberOfOfflineUsersToShowInitially)
    }));
  };

  function convertToHttps(url) {
    if (!url) return url;
    return url.startsWith('http://') ? url.replace('http://', 'https://') : url;
  }

  const renderUserRow = (user) => {
    const userAvatar = convertToHttps(user.avatar) || `${baseURL}/static/img/user-default.jpg`;
    const userBanner = convertToHttps(user.banner);
  
    if (user.integrations.length === 0) {
      return null;
    }
  
    const sortedIntegrations = user.integrations.sort((a, b) => {
      if (a.live === "true" && b.live === "true") {
        return b.viewerCount - a.viewerCount;
      } else if (a.live === "false" && b.live === "false") {
        return a.name.localeCompare(b.name);
      }
      return a.live === "true" ? -1 : 1;
    });
  
    const backgroundImageStyle = {
      backgroundImage: userBanner
        ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.25), black), url(${userBanner})`
        : 'linear-gradient(to bottom, #282830, black)',
      backgroundPosition: 'top center'
    };
  
    return (
      <tr key={user.id || user.username}>
        <td>
          <div className="streamer-row" style={backgroundImageStyle}>
            <div className="streamer-avatar">
              <a href={`${baseURL}/${user.username}`} target="_blank" rel="noopener noreferrer">
                <img src={userAvatar} alt={user.username} />
              </a>
            </div>
            <div className="streamer-info">
              <div className="streamer-username">
                <a href={`${baseURL}/${user.username}`} target="_blank" rel="noopener noreferrer">
                  {user.username}
                </a>
              </div>
              {user.isLive &&
                <div className="stream-title">Streaming {user.category} on {user.integration}<br />{user.title}</div>
              }
              <div className="platforms">
                {user.isLive &&
                  <span className="watchText">Watch on</span>
                }
                {sortedIntegrations.map((integration, idx) => {
                  const logoUrl = `${baseURL}/static/img/integrations/${integration.name.toLowerCase()}.webp`;
                  const isOffline = integration.live !== "true";
                  const viewerCount = integration.viewerCount > 0 ? `${integration.viewerCount}` : isOffline ? 'Offline' : '0';
                  const name = integration.channelName || integration.name;
                  const title = `${integration.name}\nChannel: ${name}\nTitle: ${integration.title}\nViewers: ${viewerCount.toLocaleString()}`;
                  const offlineTitle = `${integration.name} is offline.`;
                  return (
                    <a key={idx} href={integration.link} target="_blank" rel="noopener noreferrer" title={isOffline ? offlineTitle : title}>
                      <img
                        src={logoUrl}
                        alt={integration.name}
                        className={isOffline ? "offline" : ""}
                        style={{ height: "20px", width: "auto" }}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="streamer-viewers" title="Cumulative viewer count across all live channels.">
              {user.totalViewers > 0 ? user.totalViewers.toLocaleString() : ''}
            </div>
          </div>
        </td>
      </tr>
    );
  };  

  if (!isAdmin) {
    return <PageError errorCode={403} />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <PageError errorCode={error} />;
  }

  return (
    <div className="network-container page-container">
      <h2>Live ({userData.liveUsers.length})</h2>
      <table>
        <tbody>
          {userData.shownLiveUsers.map(renderUserRow)}
        </tbody>
      </table>
      <div className="pagination-buttons">
        {userData.shownLiveUsers.length < userData.liveUsers.length && (
          <div className="button-container">
            <button onClick={handleShowMoreLive}>Show {numberOfLiveUsersToShowInitially} More</button>
          </div>
        )}
        {(userData.shownLiveUsers.length > numberOfLiveUsersToShowInitially) && (userData.shownLiveUsers.length < userData.liveUsers.length) && (
          <span className="dot">•</span>
        )}
        {userData.shownLiveUsers.length > numberOfLiveUsersToShowInitially && (
          <div className="button-container">
            <button onClick={handleShowLessLive}>Show Less</button>
          </div>
        )}
      </div>
  
      <h2>Offline ({userData.offlineUsers.length})</h2>
      <table>
        <tbody>
          {userData.shownOfflineUsers.map(renderUserRow)}
        </tbody>
      </table>
      <div className="pagination-buttons">
        {userData.shownOfflineUsers.length < userData.offlineUsers.length && (
          <div className="button-container">
            <button onClick={handleShowMoreOffline}>Show {numberOfOfflineUsersToShowInitially} More</button>
          </div>
        )}
        {(userData.shownOfflineUsers.length > numberOfOfflineUsersToShowInitially) && (userData.shownOfflineUsers.length < userData.offlineUsers.length) && (
          <span className="dot">•</span>
        )}
        {userData.shownOfflineUsers.length > numberOfOfflineUsersToShowInitially && (
          <div className="button-container">
            <button onClick={handleShowLessOffline}>Show Less</button>
          </div>
        )}
      </div>
    </div>
  );  
};

export default LiveNowNetwork;
