import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../css/powerchat-pro.css';
import useAuth from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch, faCircleExclamation, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Slider, TextField, Button, ThemeProvider, Select, MenuItem,
  InputAdornment, Pagination, FormControlLabel, Checkbox, createTheme,
  Box, useMediaQuery, useTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import BarLoader from 'react-spinners/BarLoader';
import VoiceCard from './voice-card';
import ToggleSwitch from './toggle-switch';
import FormAlert from './form-alert';
import CustomAudioPlayer from './custom-audio-player';
import { voiceCategories } from './voice-categories';
import { useDropzone } from 'react-dropzone';
import { EventEmitter } from './event-emitter';
import CopyButton from './copy-button';

// Create a custom theme
const customTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#57bad8',
    },
    background: {
      paper: '#1e1f22',
      default: '#2b2d31',
    },
    text: {
      primary: '#ffffff',
      secondary: grey[400],
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#57bad8',
          },
          '&:hover fieldset': {
            borderColor: blue[300],
          },
          '&.Mui-focused fieldset': {
            borderColor: '#57bad8',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#57bad8',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(87, 186, 216, 0.2)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(87, 186, 216, 0.3)',
          },
        },
      },
    },
  },
});

const spinnerStyle = {
  position: 'relative',
  margin: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
};

const PowerchatPro = () => {
  const { user } = useAuth();
  const MAX_FILES = 1;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dropError, setDropError] = useState(null);
  const [ttsSettings, setTtsSettings] = useState({
    ttsChatsVolume: 50,
    ttsChatsMode: 0,
    chatReadSender: false,
    isProStreamer: false,
  });
  const [ttsChatsVoice, setTtsChatsVoice] = useState({});
  const [alertState, setAlertState] = useState({
    ttsSettings: {
      message: '',
      success: true,
    },
    myVoices: {
      message: '',
      success: true,
    },
    createVoice: {
      message: '',
      success: true,
    },
  });
  const [tooltip, setTooltip] = useState('');
  const [voices, setVoices] = useState({
    my: [],
    basic: [],
    pro: [],
  });
  const [voiceRules, setVoiceRules] = useState([]);
  const [proVoiceSearch, setProVoiceSearch] = useState('');
  const [proVoiceCategory, setProVoiceCategory] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [showActivatedOnly, setShowActivatedOnly] = useState(false);
  const voicesPerPage = 14;
  const [newVoiceName, setNewVoiceName] = useState('');
  const [newVoiceCategory, setNewVoiceCategory] = useState('Default');
  const [editingVoices, setEditingVoices] = useState({
    my: null,
    basic: null,
    pro: null
  });
  const [newVoiceCommand, setNewVoiceCommand] = useState('');
  const eventEmitter = new EventEmitter();
  const [submittingNewVoice, setSubmittingNewVoice] = useState(false);
  const [nameErrorText, setNameErrorText] = useState(null);
  const [commandErrorText, setCommandErrorText] = useState(null);

  const triggerAlert = (message, section, success = true) => {
    setAlertState({
      ...alertState,
      [section]: {
        message,
        success,
      }
    });
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const getVoices = async () => {
      const { data } = await axios.get('/api/chat-tts-voices');
      setVoices({
        my: data.filter(v => v.custom == true),
        basic: data.filter(v => v.voiceTypeId == 1 && !v.isNeural).map(v => ({
          ...v,
          category: v.icon == 'male' ? 'Male' : 'Female',
        })),
        pro: data.filter(v => v.custom != true && v.voiceTypeId == 12).map(v => ({
          ...v,
          category: v.icon == 'male' ? 'Male' : 'Female',
        })),
      });
    };
    const getSettings = async () => {
      const { data } = await axios.get('/api/pro-tts-settings');
      setTtsSettings(data.ttsSettings);
      let voice = data.voiceRules.find(x => x.id == data.ttsSettings.ttsChatsVoiceId) || {};
      setTtsChatsVoice(voice);
      setVoiceRules(data.voiceRules);
    };
    getVoices().then(getSettings);
  }, []);

  useEffect(() => {
    let voiceIds = voiceRules.filter(x => !x.custom).map(x => x.id);
    let customVoiceIds = voiceRules.filter(x => x.custom).map(x => x.id);
    setVoices(prevVoices => ({
      ...prevVoices,
      my: prevVoices['my'].map(voice =>
        customVoiceIds.includes(voice.id) ? { ...voice, active: true } : { ...voice, active: false }
      )
    }));
    for (let type of ['basic', 'pro']) {
      setVoices(prevVoices => ({
        ...prevVoices,
        [type]: prevVoices[type].map(voice =>
          voiceIds.includes(voice.id) ? { ...voice, active: true } : { ...voice, active: false }
        )
      }));
    }
  }, [voiceRules]);

  const handleTTSSettingChange = (setting, value) => {
    const updatedSettings = { ...ttsSettings, [setting]: value };
    setTtsSettings(updatedSettings);
  };
    
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/pro-tts-settings', {
        ...ttsSettings,
        ttsVoiceId: ttsChatsVoice.id,
      });
      triggerAlert('Settings saved successfully', 'ttsSettings');
    } catch (error) {
      console.error('Error updating TTS settings:', error);
      triggerAlert('Could not save settings', 'ttsSettings', false);
    }
  };

  const removeVoice = async (voice) => {
    let { id, name } = voice;
    if (!confirm(`Are you sure you want to delete the ${name} voice?`)) {
      return;
    }
    try {
      await axios.delete('/api/ttsmonster/voice/' + id);
      setVoices(prevVoices => ({
        ...prevVoices,
        my: prevVoices.my.filter(voice => voice.id !== id)
      }));
      triggerAlert('Voice successfully deleted', 'myVoices');
    } catch (error) {
      console.error('Error removing voice:', error);
      triggerAlert('Could not delete voice', 'myVoices', false);
    }
  };

  const findVoice = (id) => voices.my.find(x => x.id == id) || voices.basic.find(x => x.id == id) || voices.pro.find(x => x.id == id);

  const activateVoice = async (voiceId, type) => {
    try {
      await axios.post('/api/pro-voice/activate/' + voiceId, { custom: type == 'my' });
      let voice = findVoice(voiceId);
      setVoiceRules(rules => [...rules, voice]);
    } catch (error) {
      console.error('Error activating voice:', error);
    }
  };

  const deactivateVoice = async (voiceId, type) => {
    try {
      await axios.post('/api/pro-voice/deactivate/' + voiceId, { custom: type == 'my' });
      setVoiceRules(rules => rules.filter(x => x.id != voiceId));
    } catch (error) {
      console.error('Error deactivating voice:', error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map(file => ({
        file,
        preview: URL.createObjectURL(file)
      }));
      /*if (uploadedFiles.length + acceptedFiles.length > MAX_FILES) {
        setDropError('Max files: ' + MAX_FILES);
        return;
      }*/
      // Must be under 10 MB
      if (newFiles[0].file.size >= 10485760) {
        setDropError('File must be under 10 MB');
        return;
      }
      setDropError(null);
      setUploadedFiles([...newFiles]);
    },
    accept: '.mp3,.wav',
    uploadMultiple: false,
    maxFiles: MAX_FILES,
    onDropRejected: (files) => {
      if (files.length > MAX_FILES) {
        setDropError('Max files: ' + MAX_FILES);
      }
    },
    disabled: !ttsSettings.isProStreamer,
  });

  const handleLoadedAudio = (event) => {
    let { duration } = event.target;
    if (duration >= 30 && duration <= 300) {
      setDropError(null);
    } else {
      setDropError('File must be between 30 seconds and 5 minutes');
    }
  };

  const removeUploadedFile = (index) => {
    setUploadedFiles(prevFiles => {
      const newFiles = [...prevFiles];
      URL.revokeObjectURL(newFiles[index].preview);
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const onNameChanged = (e) => {
    setNewVoiceName(e.target.value);
    let unique = true;
    for (let type of ['my', 'basic', 'pro']) {
      for (let voice of voices[type]) {
        if (voice.name.toLowerCase() == e.target.value.toLowerCase().trim()) {
          unique = false;
          break;
        }
      }
    }
    setNameErrorText(unique ? null : 'Name must be unique');
  };

  const onCommandChanged = (e) => {
    let { value } = e.target;
    value = value.replace(/ /g, '').toLowerCase().trim();
    setNewVoiceCommand(value);
    if (value != '!' && !value.match(/^![A-Z0-9.\-_]+$/gi)) {
      setCommandErrorText('Command must be ! followed by letters or numbers');
      return;
    }

    let unique = value != '!male' && value != '!female';
    for (let type of ['my', 'basic', 'pro']) {
      for (let voice of voices[type]) {
        if (voice.command && voice.command.toLowerCase() == value
          || !voice.command && `!${voice.name.toLowerCase().replace(/\s+/g, '')}` == value) {
          unique = false;
          break;
        }
      }
    }
    setCommandErrorText(unique ? null : 'Command must be unique');
  };

  const createVoice = async () => {
    try {
      let form = new FormData();
      let { file } = uploadedFiles[0];
      form.append('file', file);
      
      form.append('name', newVoiceName);
      form.append('command', newVoiceCommand);
      form.append('category', newVoiceCategory);
      form.append('audioType', file.name.endsWith('mp3') ? 'audio/mpeg' : 'audio/wav');
      setSubmittingNewVoice(true);
      let { data } = await axios.post('/api/ttsmonster/create', form);
      let newVoice = {
        ...data,
        name: newVoiceName,
        value: newVoiceName,
        label: newVoiceName,
        command: newVoiceCommand,
        voiceTypeId: 12,
        icon: newVoiceCategory.toLowerCase(),
        custom: true,
      };
      setVoices(prevVoices => ({
        ...prevVoices,
        my: [...prevVoices.my, newVoice]
      }));
      setUploadedFiles([]);
      setNewVoiceName('');
      setNewVoiceCategory('Default');
      setNewVoiceCommand('');
      triggerAlert('Voice successfully created', 'createVoice');
    } catch (error) {
      console.error('Error creating voice:', error);
      if (error.response && error.response.data) {
        triggerAlert(error.response.data, 'createVoice', false);
      } else {
        triggerAlert('Could not create voice', 'createVoice', false);
      }
    } finally {
      setSubmittingNewVoice(false);
    }
  };

  const filteredProVoices = voices.pro.filter(voice => 
    voice.name.toLowerCase().includes(proVoiceSearch.toLowerCase()) &&
    (proVoiceCategory === 'all' || voice.category === proVoiceCategory) &&
    (!showActivatedOnly || voice.active)
  );

  const indexOfLastVoice = currentPage * voicesPerPage;
  const indexOfFirstVoice = indexOfLastVoice - voicesPerPage;
  const currentVoices = filteredProVoices.slice(indexOfFirstVoice, indexOfLastVoice);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const pageCount = Math.ceil(filteredProVoices.length / voicesPerPage);

  const getPageRange = () => {
    const delta = 2;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= pageCount; i++) {
      if (i === 1 || i === pageCount || (i >= currentPage - delta && i <= currentPage + delta)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  const startEditing = (voice, listType) => {
    setEditingVoices(prev => ({
      ...prev,
      [listType]: voice
    }));
  };

  const cancelEditing = (listType) => {
    setEditingVoices(prev => ({
      ...prev,
      [listType]: null
    }));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleToggle = (name) => {
    const value = ttsSettings[name] ? false : true;
    setTtsSettings({
      ...ttsSettings,
      [name]: value,
    });
  };

  const activatedVoices = () => voices.my.filter(x => x.active == true)
    .concat(voices.basic.filter(x => x.active == true))
    .concat(voices.pro.filter(x => x.active == true));

  const createVoiceReady = () => {
    return !!newVoiceName && newVoiceName != '' && newVoiceName.length >= 3 &&
      !!newVoiceCommand && !!newVoiceCommand.match(/!\w+/) &&
      !voices.my.map(x => x.name).includes(newVoiceCommand) &&
      !nameErrorText && !commandErrorText &&
      !dropError;
  };

  const commandTabFill = useCallback((event) => {
    //tab key
    if ((event.which || event.keyCode) == 9 &&
      (!newVoiceCommand || newVoiceCommand == '')) {
      setNewVoiceCommand('!' + newVoiceName.replace(/ /g, '').toLowerCase());
      setCommandErrorText(null);
    }
  }, [newVoiceName, newVoiceCommand]);

  const allVoiceText = () => {
    return activatedVoices().map(v => `${v.name} ${v.command}`).join('\n');
  };

  const chatOverlayUrl = `${process.env.BASE_URL}/${user.username}/chat`;

  return (
    <ThemeProvider theme={customTheme}>
      <div className="powerchat-pro">
        <div className="active-voices">
          <h3>Activated Voices ({activatedVoices().length})</h3>
          <div style={{ marginBottom: 8 }}>
            <CopyButton text={allVoiceText()}
              display="Copy All"
              size="1x"
            />
          </div>
          {activatedVoices().map(v => (
            <div key={v.name}>
              <b>{v.name}</b>
              <span className="command">&ensp;{v.command}</span>
            </div>
          ))}
        </div>
        <div className="settings-and-create-container">
          <section className="tts-settings">
            <form className="form-container tts-settings-content" onSubmit={handleSubmit}>
              <h3>
                Live Chat TTS Settings
                &ensp;
                <a href="https://learn.powerchat.live/overlay-configuration/powerchat-pro" target="_blank" rel="noreferrer">
                  Learn More
                </a>
              </h3>
              <div className="chat-overlay-disclaimer">
                <div>
                  <FontAwesomeIcon icon={faCircleExclamation} color="var(--gold)" />&nbsp;
                  <b>Chat Overlay must be enabled in your streaming software to function</b>
                </div>
                <div className="flex-row chat-overlay-link">
                  <CopyButton text={chatOverlayUrl} display={chatOverlayUrl} />
                </div>
              </div>
              <div className="settings-slider">
                <p>Volume: {ttsSettings.ttsChatsVolume}%</p>
                <Slider
                  value={ttsSettings.ttsChatsVolume}
                  onChange={(_, value) => handleTTSSettingChange('ttsChatsVolume', value)}
                  min={0}
                  max={100}
                  step={1}
                />
              </div>
              <div className="settings-row">
                <div className={`settings-slider tts-mode-slider ${isMobile ? 'mobile' : ''}`}>
                  <div className="tts-mode-label">
                    <label>Live chat TTS mode</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('ttsLimit')}
                      />
                      {tooltip === 'ttsLimit' && (
                        <p className="tooltip shadow-lg">
                          Off: TTS is disabled.<br/>
                          Command: Sub-only mode. Activated AI voices (e.g., !joey) are used.<br/>
                          All: Every message is read with the default TTS. Subs can use specific voice commands.
                        </p>
                      )}
                    </div>
                  </div>
                  <Slider
                    sx={{ width: '100%', margin: '10px 0' }}
                    value={ttsSettings.ttsChatsMode}
                    onChange={(_, value) => handleTTSSettingChange('ttsChatsMode', value)}
                    step={1}
                    track={false}
                    marks={[
                      { value: 0, label: 'Off' },
                      { value: 1, label: 'Command (Subs Only)' },
                      { value: 2, label: 'All' },
                    ]}
                    min={0}
                    max={2}
                  />
                </div>
                <Select
                  sx={{ maxHeight: 40, width: 200 }}
                  value={ttsChatsVoice}
                  onChange={(e) => setTtsChatsVoice(e.target.value)}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {voiceRules.filter(a => a.voiceTypeId == 1).sort((a,b) => a.id - b.id).map((rule) => (
                    <MenuItem key={rule.name} value={rule}>
                      {rule.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="flex-row" style={{ gap: 20 }}>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="chatReadSender"
                    onChange={() => handleToggle('chatReadSender')}
                    checked={ttsSettings.chatReadSender}
                  />
                  <label htmlFor="chatReadSender">Read Sender Name</label>
                </div>

                <div className="toggle-container">
                  <ToggleSwitch
                    name="hideChats"
                    onChange={() => handleToggle('hideChats')}
                    checked={ttsSettings.hideChats}
                  />
                  <label htmlFor="hideChats">Hide Chats</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('hideChats')}
                    />
                    {tooltip === 'hideChats' && (
                      <p className="tooltip shadow-lg">
                        Chats will no longer display on overlay. TTS may still play.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <button className="primary-btn" type="submit">Save</button>
              <FormAlert alertState={alertState.ttsSettings} margin={0} />
            </form>
          </section>
          <section className={"create-voice " + (ttsSettings.isProStreamer ? '' : 'disabled')}>
            <h3>
              Create a voice
              &ensp;
              <a href="https://learn.powerchat.live/overlay-configuration/powerchat-pro" target="_blank" rel="noreferrer">
                Learn More
              </a>
            </h3>
            <a href="https://tts.monster" target="_blank" rel="noreferrer" className="logo">
              <img src="/static/img/powered-by-ttsmonster.webp" alt="TTS Monster Logo" />
            </a>
          {!ttsSettings.isProStreamer && (
            <div className="unlock-note">
              <FontAwesomeIcon icon={faCircleExclamation} color="var(--gold)" />&nbsp;
              You must <a href="/account-settings?tab=powerchat%20pro">subscribe</a> to Powerchat Pro Streamer to unlock voice cloning
            </div>
          )}
            <div className={`dropzone ${isDragActive ? 'active' : ''}`} {...getRootProps()}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop the file here ...</p> :
                  <p>Drag &amp; drop an MP3 or WAV file, or click to select a file</p>
              }
            </div>
            {dropError && <p className="invalid-message">{dropError}</p>}
            <div className="uploaded-files">
              {uploadedFiles.map((file, index) => (
                <div key={index} className="uploaded-file">
                  <CustomAudioPlayer
                    src={file.preview}
                    title={file.file.name}
                    onDelete={() => removeUploadedFile(index)}
                    onLoadedMetadata={handleLoadedAudio}
                  />
                </div>
              ))}
            </div>
            {uploadedFiles.length > 0 && (
              <div className="new-voice-details">
                <TextField
                  label="Voice name"
                  value={newVoiceName}
                  onChange={onNameChanged}
                  fullWidth
                  margin="normal"
                  spellCheck={false}
                  maxLength={50}
                  error={!!nameErrorText}
                  helperText={nameErrorText}
                />
                <TextField
                  label="Command"
                  value={newVoiceCommand}
                  onChange={onCommandChanged}
                  onKeyDown={commandTabFill}
                  fullWidth
                  margin="normal"
                  placeholder={`!${newVoiceName.toLowerCase().replace(/\s+/g, '')}`}
                  spellCheck={false}
                  error={!!commandErrorText}
                  helperText={commandErrorText}
                  sx={{ marginTop: 0 }}
                />
                <Select
                  value={newVoiceCategory}
                  onChange={(e) => setNewVoiceCategory(e.target.value)}
                  fullWidth
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  {Object.keys(voiceCategories).map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
                <div className="disclaimer">
                  <FontAwesomeIcon icon={faInfoCircle} color="#fff" />&nbsp;
                  I certify that I possess the necessary rights to upload and clone this voice and agree not to use the content for any unlawful or harmful activities.
                </div>
                <Button
                  disabled={!createVoiceReady() || submittingNewVoice}
                  variant="contained"
                  className="create-voice-btn"
                  onClick={createVoice}
                >
                  Create Voice
                </Button>
                <div style={spinnerStyle}>
                  <BarLoader color="#ffffff" loading={submittingNewVoice} size={20} />
                </div>
              </div>
            )}
            <FormAlert alertState={alertState.createVoice} width={800} />
          </section>
        </div>

      {ttsSettings.isProStreamer && (
        <section className="my-voices">
          <h3>My Voices ({voices.my.length}/5)</h3>
        {voices.my.length > 0 && (
          <div className={`voice-grid my-voices-grid ${isMobile ? 'mobile' : ''}`}>
            {voices.my.map((voice) => (
              <VoiceCard
                key={`my-${voice.id}`}
                voice={voice}
                type="my"
                listId="my-voices"
                onActivate={() => activateVoice(voice.id, 'my')}
                onDeactivate={() => deactivateVoice(voice.id, 'my')}
                canDeactivate={voice.id != ttsChatsVoice.id}
                onRemove={() => removeVoice(voice)}
                onEdit={() => startEditing(voice, 'my')}
                isEditing={editingVoices.my && editingVoices.my.id === voice.id}
                onCancel={() => cancelEditing('my')}
                eventEmitter={eventEmitter}
              />
            ))}
          </div>
        )}
          <FormAlert alertState={alertState.myVoices} width={400} />
        </section>
      )}

        <section className="basic-voices">
          <h3>Basic Voices</h3>
          <div className={`voice-grid ${isMobile ? 'mobile' : ''}`}>
            {voices.basic.map((voice) => (
              <VoiceCard
                key={`basic-${voice.id}`}
                voice={voice}
                type="basic"
                listId="basic-voices"
                onActivate={() => activateVoice(voice.id, 'basic')}
                onDeactivate={() => deactivateVoice(voice.id, 'basic')}
                canDeactivate={voice.id != ttsChatsVoice.id}
                onEdit={() => startEditing(voice, 'basic')}
                isEditing={editingVoices.basic && editingVoices.basic.id === voice.id}
                onCancel={() => cancelEditing('basic')}
                eventEmitter={eventEmitter}
              />
            ))}
          </div>
        </section>

        <section className="pro-voices">
          <h3>Pro Voices
            <a href="https://tts.monster" target="_blank" rel="noreferrer" className="logo">
              <img src="/static/img/powered-by-ttsmonster.webp" alt="TTS Monster Logo" />
            </a>
          {!ttsSettings.isProStreamer && (
            <div className="unlock-note">
              <FontAwesomeIcon icon={faCircleExclamation} color="var(--gold)" />&nbsp;
              You must <a href="/account-settings?tab=powerchat%20pro">subscribe</a> to Powerchat Pro Streamer to unlock these voices
            </div>
          )}
          </h3>
          <Box className={`pro-voices-controls ${isMobile ? 'mobile' : ''}`}>
            <TextField
              className="pro-voices-search"
              variant="outlined"
              size="small"
              placeholder="Search voices"
              value={proVoiceSearch}
              onChange={(e) => setProVoiceSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} color="#57bad8" />
                  </InputAdornment>
                ),
              }}
            />
            <Select
              className="pro-voices-category"
              value={proVoiceCategory}
              onChange={(e) => setProVoiceCategory(e.target.value)}
              size="small"
              sx={{
                backgroundColor: 'background.paper',
                color: 'text.primary',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.light',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                },
                '& .MuiSelect-icon': {
                  color: 'primary.main',
                },
              }}
            >
              <MenuItem value="all">All Categories</MenuItem>
              {Object.keys(voiceCategories).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showActivatedOnly}
                  onChange={(e) => setShowActivatedOnly(e.target.checked)}
                  color="primary"
                  size="small"
                />
              }
              label="Activated Only"
              className="activated-only-checkbox"
            />
          </Box>
          <div className={`voice-grid pro-voices-grid ${isMobile ? 'mobile' : ''}`}>
            {currentVoices.map((voice) => (
              <VoiceCard
                key={`pro-${voice.id}`}
                voice={voice}
                type="pro"
                listId="pro-voices"
                disabled={!ttsSettings.isProStreamer}
                onActivate={() => activateVoice(voice.id, 'pro')}
                onDeactivate={() => deactivateVoice(voice.id, 'pro')}
                canDeactivate={voice.id != ttsChatsVoice.id}
                onEdit={() => startEditing(voice, 'pro')}
                isEditing={editingVoices.pro && editingVoices.pro.id === voice.id}
                onCancel={() => cancelEditing('pro')}
                eventEmitter={eventEmitter}
              />
            ))}
          </div>
          <div className={`pagination-container ${isMobile ? 'mobile' : ''}`}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="large"
              renderItem={(item) => {
                if (item.type === 'previous' || item.type === 'next') {
                  return (
                    <Button {...item} className="pagination-button"
                      disabled={(item.type === 'previous' && currentPage === 1)
                        || (item.type === 'next' && currentPage === pageCount)}>
                      {item.type === 'previous' ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />}
                    </Button>
                  );
                }
                if (item.type === 'page') {
                  return (
                    <Button
                      {...item}
                      className={`pagination-button ${item.selected ? 'Mui-selected' : ''}`}
                    >
                      {item.page}
                    </Button>
                  );
                }
                if (item.type === 'start-ellipsis' || item.type === 'end-ellipsis') {
                  return (
                    <div {...item} onClick={null} className="pagination-button ellipsis">...</div>
                  );
                }
                return null;
              }}
              siblingCount={1}
              boundaryCount={0}
            >
              {getPageRange().map((pageNumber, index) => (
                <Button
                  key={index}
                  onClick={() => typeof pageNumber === 'number' && handlePageChange(null, pageNumber)}
                  className={`pagination-button ${currentPage === pageNumber ? 'Mui-selected' : ''}`}
                  disabled={pageNumber === '...'}
                >
                  {pageNumber}
                </Button>
              ))}
            </Pagination>
          </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default PowerchatPro;
