import { 
  faUser, faMars, faVenus, faBalanceScale, faFilm, 
  faMicrophone, faStar, faGamepad, faMask, faDragon, 
  faPaintBrush, faVideo, faNewspaper, faGlobe,
  faMusic, 
  faLaughSquint} from '@fortawesome/free-solid-svg-icons';

export const voiceCategories = {
  Default: { icon: faUser, color: '#57bad8' },
  Male: { icon: faMars, color: '#4caf50' },
  Female: { icon: faVenus, color: '#ff4081' },
  Politics: { icon: faBalanceScale, color: '#9c27b0' },
  Cartoon: { icon: faPaintBrush, color: '#ffc107' },
  'Content Creator': { icon: faMicrophone, color: '#ff5722' },
  Celebrity: { icon: faStar, color: '#f44336' },
  'Video Game': { icon: faGamepad, color: '#2196f3' },
  Movie: { icon: faFilm, color: '#795548' },
  Fantasy: { icon: faDragon, color: '#009688' },
  Anime: { icon: faMask, color: '#e91e63' },
  TV: { icon: faVideo, color: '#3f51b5' },
  News: { icon: faNewspaper, color: '#607d8b' },
  'Foreign Accent': { icon: faGlobe, color: '#00bcd4' },
  'Singer/Vocalist': { icon: faMusic, color: '#673ab7' },
  Comedy: { icon: faLaughSquint, color: '#ffeb3b' },
  // Add any new categories here if needed
};
