import React from 'react';
import '../css/hourglass.css';

const Hourglass = ({ value, max,
  primaryColor, secondaryColor, backgroundColor,
  playing = true, height = 180, width = 160 }) => {
  
  return (
    <div className="visual-hourglass" style={{
        "--frame-color": primaryColor,
        "--sand-color": secondaryColor,
        "--glass-color": backgroundColor,
        "--top-sand": (value * 100 / max) + "%",
        "--bottom-sand": ((max - value) / max * 100 - 15) + "%",
        "--blob-transform": (value * 90 / max) + 'px',
        "--sand-level": (value * 100 / max),
        "--blob-size": ((value / max) * 230 + 50) + 'px',
      }}
    >
      <div className="frame" style={{ height, width }}>
        <div className="top" />
        <div className="bottom">
          {playing && <div className="drip" />}
          <div className="blob" />
          <div className="glass" />
        </div>
      </div>
    </div>
  );
};

export default Hourglass;
