import React from 'react';

const NmiPayment = ({ useGoogle, handlePayButtonClick = () => {}, disabled = false }) => {
  return (
    <div>
    {useGoogle ? (
      <div id="google-pay-button" />
    ) : (
      <button type="submit" onClick={handlePayButtonClick} className="primary-btn" id="payButton" disabled={disabled}>
        Submit
      </button>
    )}
    </div>
  );
};

export default NmiPayment;
