import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import '../css/subathon-settings.css';
import useAuth from '../hooks/useAuth';
import FormAlert from './form-alert';
import ToggleSwitch from './toggle-switch';
import TimePicker from './time-picker';
import ColorInput from './color-input';
import FontSelector from './font-selector';
import CustomSelect from './custom-select';
import FlipNumbers from 'react-flip-numbers';
import ProgressBar from '../components/progress-bar';
import Clock from '../components/clock';
import Hourglass from '../components/hourglass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faRotateRight,
  faInfoCircle, faPlay, faPause, faStop, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Sockette from 'sockette';

const SubathonSettings = ({ fontOptions, fontStyles, isMod = false }) => {
  const { user } = useAuth();
  const username = user.username;
  const descriptionMaxLength = 500;
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const maxLinesRegex = /^(?:[^\r\n]*(?:\r\n?|\n)){0,10}[^\r\n]*$/;
  const [playing, setPlaying] = useState(false);
  const [saving, setSaving] = useState(false);
  const interval = useRef(null);
  const totalTime = useRef(0);
  const [tooltip, setTooltip] = useState('');
  const [addedText, setAddedText] = useState('');
  const [addedTextVisible, setAddedTextVisible] = useState(false);
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const [saveDefaultTimer, setSaveDefaultTimer] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [formData, setFormData] = useState({
    defaultTimer: 0,
    timer: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    donationsEnabled: true,
    donationsTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    subsEnabled: true,
    subsTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    followsEnabled: true,
    followsTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    hostsEnabled: true,
    hostsTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    clockEnabled: true,
    textFont: 'Default',
    textColor: '#ffffff',
    timerColor: '#ffffff',
    clockStyle: 'clock',
    startTime: Date.now(),
    duration: 86400,
    status: 'stopped',
    backgroundColor: 'transparent',
    clockPrimaryColor: '#ffffff',
    clockSecondaryColor: '#ffffff',
    clockBackgroundColor: "#000000",
  });

  const [spoiler, setSpoiler] = useState(null);
  const toggleSpoiler = (name) => {
    setSpoiler(spoiler === name ? null : name);
  };

  const clockOptions = [
    { label: 'None', value: 'none', },
    { label: 'Clock', value: 'clock', },
    { label: 'Clock (Roman numerals)', value: 'clock-roman' },
    { label: 'Hourglass', value: 'hourglass' },
    { label: 'Progress bar', value: 'goal-default' },
    { label: 'Pill', value: 'goal-pill' },
    { label: 'Tilted Back', value: 'goal-tilted-back' },
    { label: 'Tilted Forward', value: 'goal-tilted-forward' },
  ];

  useEffect(() => {
    const getSubathon = async () => {
      const { data } = await axios.get(`/pubapi/subathon?username=${username}`);
      let {
        startTime, duration, status,
        defaultTimer,
        donationsTime,
        subsTime,
        followsTime,
        hostsTime,
      } = data;

      let timer = 0;
      if (status == 'playing' && startTime != null && duration != null) {
        let diff = Math.round((Date.now() - startTime)/1000);
        timer = timerFromSeconds(Math.max(0, duration - diff));
      } else if (status == 'paused') {
        timer = timerFromSeconds(Math.max(0, duration));
      } else if (defaultTimer != null) {
        timer = timerFromSeconds(Math.max(0, defaultTimer));
      }
      if (getTotalSeconds(timer) == 0) {
        status = 'stopped';
      }
      setPlaying(status == 'playing');
      let initialData = {
        ...formData,
        ...data,
        timer,
        donationsTime: timerFromSeconds(donationsTime),
        subsTime: timerFromSeconds(subsTime),
        followsTime: timerFromSeconds(followsTime),
        hostsTime: timerFromSeconds(hostsTime),
      };
      setInitialData(initialData);

      setTimeout(() => setFormData(initialData), 0);
    };
    getSubathon();
  }, []);

  useEffect(() => {
    clearInterval(interval.current);
    if (playing) {
      interval.current = setInterval(() => {
        setFormData((formData) => ({
          ...formData,
          timer: {
            ...formData.timer,
            seconds: formData.timer.seconds - 1,
          },
        }));
      }, 1000);
    } else {
      clearInterval(interval.current);
    }
  }, [playing]);

  useEffect(() => {
    totalTime.current = formData.timer.hours * 3600
      + formData.timer.minutes * 60
      + formData.timer.seconds;
    
    if (totalTime.current == 0) {
      setPlaying(false);
      setFormData((formData) => ({
        ...formData,
        status: 'stopped',
      }));
    }
  }, [formData.timer]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const timerFromSeconds = (seconds) => ({
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor(seconds / 60) % 60,
    seconds: seconds % 60,
  });

  const getTotalSeconds = (time) => time.hours * 3600 + time.minutes * 60 + time.seconds;

  const setTimerFromSeconds = (seconds) => {
    setFormData({
      ...formData,
      timer: timerFromSeconds(seconds),
    });
  };

  const toggleStart = async () => {
    let play = playing;
    let duration = totalTime.current;
    let startTime = Date.now();

    try {
      await axios.post('/api/subathon-control', {
        play: !play,
        startTime,
        duration,
        defaultTimer: saveDefaultTimer == true ? getTotalSeconds(formData.timer) : null
      });
      setPlaying(!playing);
      setFormData({
        ...formData,
        status: playing ? 'paused' : 'playing',
      });
      if (saveDefaultTimer) {
        setFormData((formData) => ({
          ...formData,
          defaultTimer: getTotalSeconds(formData.timer),
        }));
        flashAddedText('Saved');
      }
      setSaveDefaultTimer(false);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 429) {
        triggerAlert('Too many requests. Wait 5 minutes and retry', false);
      } else {
        triggerAlert(`Could not ${play ? 'pause' : 'start'} subathon`, false);
      }
    }
  };

  const stop = async () => {
    let defaultTimer = timerFromSeconds(formData.defaultTimer);
    let timeString = `${String(defaultTimer.hours).padStart(2, '0')}:${String(defaultTimer.minutes).padStart(2, '0')}:${String(defaultTimer.seconds).padStart(2, '0')}`;
    let confirmMessage = formData.status == 'stopped' && totalTime.current == 0
      ? `Reset subathon? This will set the timer to ${timeString}.`
      : `Stop subathon? This will reset the timer to ${timeString}.`;
    if (confirm(confirmMessage)) {
      try {
        await axios.post('/api/subathon-control', {
          play: false,
          stop: true,
          startTime: Date.now(),
          duration: formData.defaultTimer,
        });
        setTimerFromSeconds(formData.defaultTimer);
        setFormData((formData) => ({
          ...formData,
          status: 'stopped',
        }));
        setPlaying(false);
      } catch (err) {
        console.error(err);
        if (err.response && err.response.status == 429) {
          triggerAlert('Too many requests. Wait 5 minutes and retry', false);
        } else {
          triggerAlert('Could not stop subathon', false);
        }
      }
    }
  };

  const flashAddedText = (text) => {
    setAddedText(text);
    setAddedTextVisible(true);
    setTimeout(() => setAddedTextVisible(false), 500);
  };

  const addMinutes = useCallback(async (minutes) => {
    try {
      let add = (formData.timer.minutes + minutes < 0 && formData.timer.hours < 1)
        ? -formData.timer.minutes
        : minutes;
      await axios.post('/api/subathon-control', {
        startTime: Date.now(),
        duration: totalTime.current + (add * 60),
        play: true,
      });

      setFormData((formData) => ({
        ...formData,
        timer: {
          ...formData.timer,
          minutes: formData.timer.minutes + minutes,
        },
      }));
      flashAddedText(`${minutes > 0 ? '+' : ''}${minutes} min`);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 429) {
        triggerAlert('Too many requests. Wait 5 minutes and retry', false);
      }
    }
  }, [formData.timer.minutes]);

  //More specialized function prevents Minutes from visually flickering
  const addHours = useCallback(async (hours) => {
    try {
      let add = (formData.timer.hours + hours < 0)
        ? -formData.timer.hours
        : hours;
      await axios.post('/api/subathon-control', {
        startTime: Date.now(),
        duration: totalTime.current + (add * 3600),
        play: true,
      });

      setFormData((formData) => ({
        ...formData,
        timer: {
          ...formData.timer,
          hours: formData.timer.hours + hours,
        },
      }));
      flashAddedText(`${hours > 0 ? '+' : ''}${hours} hr`);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status == 429) {
        triggerAlert('Too many requests. Wait 5 minutes and retry', false);
      }
    }
  }, [formData.timer.hours]);

  const autofillDescription = () => {
    let rules = [];
    let timeToDescription = (time) => {
      if (!time.hours && !time.minutes && !time.seconds) {
        return '';
      } else if (!time.minutes && !time.seconds) {
        return time.hours + ' hours' + (time.hours > 1 ? 's' : '');
      } else if (!time.hours && !time.seconds) {
        return time.minutes + ' minute' + (time.minutes > 1 ? 's' : '');
      } else if (!time.hours && !time.minutes) {
        return time.seconds + ' second' + (time.seconds > 1 ? 's' : '');
      } else {
        return (
          (time.hours > 0 ? `${time.hours}hr ` : '') + 
          (time.minutes > 0 ? `${time.minutes}min ` : '') +
          (time.seconds > 0 ? `${time.seconds}sec ` : '')
        ).trim();
      }
    };
    let multiplier = 1;
    let donationTime = { ...formData.donationsTime };
    if (!donationTime.hours && !donationTime.minutes && donationTime.seconds) {
      multiplier = 60 / donationTime.seconds;
      if (Math.floor(multiplier) == multiplier ) {
        donationTime.seconds = 0;
        donationTime.minutes = 1;
      } else {
        multiplier = 1;
      }
    }

    if (formData.donationsEnabled && getTotalSeconds(donationTime) > 0) {
      rules.push(`$${multiplier} = ${timeToDescription(donationTime)}`);
    }
    if (formData.subsEnabled && getTotalSeconds(formData.subsTime) > 0) {
      rules.push(`1 sub = ${timeToDescription(formData.subsTime)}`);
    }
    if (formData.followsEnabled && getTotalSeconds(formData.followsTime) > 0) {
      rules.push(`1 follow = ${timeToDescription(formData.followsTime)}`);
    }
    if (formData.hostsEnabled && getTotalSeconds(formData.hostsTime) > 0) {
      rules.push(`1 host = ${timeToDescription(formData.hostsTime)}`);
    }
    let description = rules.join('\n');
    setFormData({
      ...formData,
      description,
    });
  };

  const handleFontChange = (option, action) => {
    setFormData({
      ...formData,
      [action.name]: option.value,
    });
  };

  const handleSelectChange = (name, option) => {
    setFormData({
      ...formData,
      [name]: option.value,
    });
  };

  const handleChange = (event) => {
    let { name } = event.target;
    let value = event.target.type == 'number' ? Number(event.target.value) : event.target.value;
    if (name == 'description' && !!value) {
      if (value.length > descriptionMaxLength) {
        value = value.substring(value, descriptionMaxLength);
      }
      let newlineRegex = value.match(maxLinesRegex);
      if (!newlineRegex) {
        return;
      }
    }
    setFormData({
      ...formData,
      [event.target.name]: value,
    });
  };

  const handleTimerChange = (event, section) => {
    setFormData({
      ...formData,
      [event.target.name]: section,
    });
  };

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    try {
      setSaving(true);
      await axios.post('/api/subathon-settings', {
        ...formData,
        defaultTimer: saveDefaultTimer == true ? getTotalSeconds(formData.timer) : formData.defaultTimer,
        donationsTime: getTotalSeconds(formData.donationsTime),
        subsTime: getTotalSeconds(formData.subsTime),
        followsTime: getTotalSeconds(formData.followsTime),
        hostsTime: getTotalSeconds(formData.hostsTime),
      });
      if (saveDefaultTimer) {
        setFormData((formData) => ({
          ...formData,
          defaultTimer: getTotalSeconds(formData.timer),
        }));
        flashAddedText('Saved');
      }
      setInitialData(formData);
      setSaveDefaultTimer(false);
      triggerAlert('Subathon settings saved');
    } catch (err) {
      console.error(err);
      triggerAlert('Could not save Subathon settings', false);
    } finally {
      setSaving(false);
    }
  }, [formData, saveDefaultTimer]);

  const handleCancel = () => {
    delete initialData.timer;
    delete initialData.startTime;
    delete initialData.duration;
    delete initialData.defaultTimer;
    setFormData((formData) => ({
      ...formData,
      ...initialData,
    }));
  };

  const processMessage = (message) => {
    if (message.add) {
      setFormData((formData) => ({
        ...formData,
        timer: {
          ...formData.timer,
          seconds: formData.timer.seconds + message.add,
        },
      }));
    }
  };

  useEffect(() => {
    const sockette = new Sockette(`wss://${baseUrl}/${username.toLowerCase()}_subathon`,
      {
        onopen: function (event) {
          event.target.send(`Remote client log - ${username} - subathon opened`);
        },
        onmessage: function (event) {
          event.target.send(`Remote client log - ${username} subathon - [message] Data received from server: ${event.data}`);
          if (event.data == 'pong') {
            return;
          }

          const message = JSON.parse(event.data);
          processMessage(message);
        },
        onclose: function (event) {
          if (event.wasClean) {
            console.info(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
          } else {
            console.error(`[close] Connection died`);
          }
        },
        onerror: function (error) {
          console.error(`[error] ${error.message}`);
        },
      }
    );
    setInterval(() => {
      sockette && sockette.send(`ping from: ${username} - subathon settings`);
    }, 30 * 1000);
  }, []);

  const primaryClockTooltip = () => {
    switch (formData.clockStyle) {
      case 'clock':
      case 'clock-roman':
        return "Color of clock numbers and second hand.";
      case 'hourglass':
        return "Color of hourglass frame.";
      default:
        return "Color of the unfilled portion of the goal bar.";
    }
  };

  const secondaryClockTooltip = () => {
    switch (formData.clockStyle) {
      case 'clock':
      case 'clock-roman':
        return "Color of minute/hour hands and clock border.";
      case 'hourglass':
        return "Color of hourglass sand.";
      default:
        return "Color of the filled portion of the goal bar.";
    }
  };

  const backgroundClockTooltip = () => {
    switch (formData.clockStyle) {
      case 'clock':
      case 'clock-roman':
        return "Color of the clock face.";
      case 'hourglass':
        return "Color of the glass tint.";
      default:
        return "Color of the background of the goal bar.";
    }
  };

  const getClockName = () => {
    switch (formData.clockStyle) {
      case 'clock':
      case 'clock-roman':
        return 'Clock';
      case 'hourglass':
        return 'Hourglass';
      default:
        return 'Bar';
    }
  };

  const atZero = () => formData.timer.seconds == 0 && formData.timer.hours == 0 && formData.timer.minutes == 0;

  return (
    <form onSubmit={handleSubmit} className="subathon-settings">
      <div className="flex-row flex-wrap">
        <div>
          <div className="flex-row" style={{ alignItems: 'center' }}>
            <label>Save Default Timer</label>
            <input type="checkbox" checked={!!saveDefaultTimer} disabled={playing}
              onChange={() => setSaveDefaultTimer(!saveDefaultTimer)} />
            <div className="icon-btn tooltip-wrapper">
              <FontAwesomeIcon
                icon={faInfoCircle}
                color="#57bad8"
                onClick={() => toggleTooltip('default-timer')}
                disabled={playing}
              />
              {tooltip === 'default-timer' && (
                <p className="tooltip left shadow-lg">
                  Saves the current timer value as the default when timer is started or settings are saved.
                </p>
              )}
            </div>
          </div>
          <div className="flex-row" style={{ alignItems: 'center' }}>
            <TimePicker name="timer"
              onChange={handleTimerChange}
              time={formData.timer}
              seconds={formData.timer.seconds}
              disabled={playing}
            />
            <div className={"note flash-text " + (addedTextVisible ? '' : 'invisible')}>
              {addedText}
            </div>
          </div>
          <div className="flex-row">
            <button type="button" className="secondary-btn" onClick={toggleStart} disabled={atZero()}>
            {playing ? (
              <div>
                Pause&emsp;
                <FontAwesomeIcon icon={faPause} color="#5bc7ec" size="lg" />
              </div>
            ) : (
              <div>
                Start&emsp;
                <FontAwesomeIcon icon={faPlay} color="#5bc7ec" size="lg" />
              </div>
            )}
            </button>
            <button type="button" className="secondary-btn" onClick={stop}>
              {(atZero() && formData.status == 'stopped') ? (
                <div>
                  Reset&emsp;
                  <FontAwesomeIcon icon={faRotateRight} color="#5bc7ec" size="lg" />
                </div>
              ) : (
                <div>
                  Stop&emsp;
                  <FontAwesomeIcon icon={faStop} color="#5bc7ec" size="lg" />
                </div>
              )}
            </button>
          </div>
          <div className="time-buttons">
            <button type="button" onClick={() => addMinutes(1)} disabled={!playing}>
              +1 min
            </button>
            <button type="button" onClick={() => addMinutes(10)} disabled={!playing}>
              +10 min
            </button>
            <button type="button" onClick={() => addHours(1)} disabled={!playing}>
              +1 hour
            </button>
            <button type="button" onClick={() => addMinutes(-1)} disabled={!playing}>
              -1 min
            </button>
            <button type="button" onClick={() => addMinutes(-10)} disabled={!playing}>
              -10 min
            </button>
            <button type="button" onClick={() => addHours(-1)} disabled={!playing}>
              -1 hour
            </button>
          </div>
        </div>
        <div className="rules">
          <ToggleSwitch
            name="donationsEnabled"
            onChange={() => handleToggle("donationsEnabled")}
            checked={formData.donationsEnabled}
          />
          <label className="label">Donations</label>
          <TimePicker onChange={handleTimerChange}
            name="donationsTime"
            time={formData.donationsTime}
            disabled={!formData.donationsEnabled}
          />
          <div className="icon-btn tooltip-wrapper">
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="#57bad8"
              onClick={() => toggleTooltip('donations')}
            />
            {tooltip === 'donations' && (
              <p className="tooltip left shadow-lg">
                Amount of time to add per dollar.<br/>
                Ex. 00:01:00 adds 5 minutes for a $5 donation.
              </p>
            )}
          </div>
          <ToggleSwitch
            name="subsEnabled"
            onChange={() => handleToggle("subsEnabled")}
            checked={formData.subsEnabled}
          />
          <label>Subscriptions</label>
          <TimePicker onChange={handleTimerChange}
            name="subsTime"
            time={formData.subsTime}
            disabled={!formData.subsEnabled}
          />
          <div className="icon-btn tooltip-wrapper">
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="#57bad8"
              onClick={() => toggleTooltip('subs')}
            />
            {tooltip === 'subs' && (
              <p className="tooltip left shadow-lg">
                Amount of time to add per subscription
              </p>
            )}
          </div>
          <ToggleSwitch
            name="followsEnabled"
            onChange={() => handleToggle("followsEnabled")}
            checked={formData.followsEnabled}
          />
          <label>Follows</label>
          <TimePicker onChange={handleTimerChange}
            name="followsTime"
            time={formData.followsTime}
            disabled={!formData.followsEnabled}
          />
          <div className="icon-btn tooltip-wrapper">
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="#57bad8"
              onClick={() => toggleTooltip('follows')}
            />
            {tooltip === 'follows' && (
              <p className="tooltip left shadow-lg">
                Amount of time to add per follow
              </p>
            )}
          </div>
          <ToggleSwitch
            name="hostsEnabled"
            onChange={() => handleToggle("hostsEnabled")}
            checked={formData.hostsEnabled}
          />
          <label>Hosts</label>
          <TimePicker onChange={handleTimerChange}
            name="hostsTime"
            time={formData.hostsTime}
            disabled={!formData.hostsEnabled}
          />
          <div className="icon-btn tooltip-wrapper">
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="#57bad8"
              onClick={() => toggleTooltip('hosts')}
            />
            {tooltip === 'hosts' && (
              <p className="tooltip left shadow-lg">
                Amount of time to add per host/raid event.
              </p>
            )}
          </div>
          <button type="button" onClick={autofillDescription}>
            Autofill description&nbsp;
            <FontAwesomeIcon icon={faArrowRight} color="white" />
          </button>
        </div>
        <div className="flex-column text-controls">
          <label htmlFor="title">Title</label>
          <input type="text" name="title"
            placeholder="Subathon title"
            onChange={handleChange}
            maxLength={140}
            value={formData.title || ''} />
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            placeholder="Time rules, goals, etc."
            maxLength={2000}
            onChange={handleChange}
            value={formData.description || ''}
          />
          <div className="char-remaining">
            {descriptionMaxLength - (formData.description ? formData.description.length : 0)}
          </div>
          <div className="flex-row" style={{ marginTop: 5 }}>
            <label htmlFor="finishMedia">Finish Media</label>
            <div className="icon-btn tooltip-wrapper">
              <FontAwesomeIcon
                icon={faInfoCircle}
                color="#57bad8"
                onClick={() => toggleTooltip('finishMedia')}
              />
              {tooltip === 'finishMedia' && (
                <p className="tooltip shadow-lg">
                  Plays when the countdown finishes. Must be a YouTube video.
                </p>
              )}
            </div>
          </div>
          <input type="url" name="finishMedia" placeholder="Input a YouTube link"
            value={formData.finishMedia || ''} onChange={handleChange} />
        </div>
      </div>
      <div className="overlay-spoiler-notes">
        <div className="overlay-spoiler">
          <div className="overlay-spoiler-header">
            <h4 className="text-light-blue" onClick={() => toggleSpoiler('subathonColors')}>
              { spoiler !== 'subathonColors' ? (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  color="#57bad8"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  color="#57bad8"
                />
              )}
              &nbsp;&nbsp;&nbsp;
              Customize Theme
            </h4>
          </div>
          <div className={`overlay-spoiler-body ${spoiler === 'subathonColors' ? 'open' : 'closed'}`}>
            <div className="settings-sections-wrapper">
              <div className="settings-section" style={{ paddingRight: "2em" }}>
                <div className="input-row">
                  <div className="flex-row">
                    <label htmlFor="textColor">Text Color</label>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      name="textColor"
                      value={formData.textColor || '#ffffff'}
                      onValueChange={(value, name) => handleChange({ target: { value, name } })}
                      disabled={isMod}
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="flex-row">
                    <label htmlFor="textColor">Timer Color</label>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      name="timerColor"
                      value={formData.timerColor || '#ffffff'}
                      onValueChange={(value, name) => handleChange({ target: { value, name } })}
                      disabled={isMod}
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="flex-row">
                    <label htmlFor="backgroundColor">Subathon Background Color</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('backgroundColor')}
                      />
                      {tooltip === 'backgroundColor' && (
                        <p className="tooltip shadow-lg">
                          <strong>Background color of Subathon overlay.</strong><br />You can type in a hexadecimal color code or click on the color box to the left to select a color via the picker.<br /><br />Default: transparent
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      opacity={true}
                      name="backgroundColor"
                      value={formData.backgroundColor || 'transparent'}
                      onValueChange={(value, name) => handleChange({ target: { value, name } })}
                      disabled={isMod}
                    />
                  </div>
                </div>
              </div>
              <div className="settings-section">
                <label htmlFor="clockStyle">Visual Theme</label>
                <CustomSelect
                  name="clockStyle"
                  className="clock-select-dropdown"
                  classNamePrefix="react-select"
                  value={formData.clockStyle}
                  onChange={handleSelectChange}
                  options={clockOptions}
                />
                <div className="input-row">
                  <div className="flex-row">
                    <label htmlFor="clockPrimaryColor">{getClockName()} Primary Color</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('clockPrimaryColor')}
                      />
                      {tooltip === 'clockPrimaryColor' && (
                        <p className="tooltip shadow-lg">
                        {primaryClockTooltip()}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      name="clockPrimaryColor"
                      value={formData.clockPrimaryColor || '#ffffff'}
                      onValueChange={(value, name) => handleChange({ target: { value, name } })}
                      disabled={formData.clockStyle == 'none' || isMod}
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="flex-row">
                    <label htmlFor="clockSecondaryColor">{getClockName()} Secondary Color</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('clockSecondaryColor')}
                      />
                      {tooltip === 'clockSecondaryColor' && (
                        <p className="tooltip shadow-lg">
                          {secondaryClockTooltip()}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      name="clockSecondaryColor"
                      value={formData.clockSecondaryColor || '#5bc7ec'}
                      onValueChange={(value, name) => handleChange({ target: { value, name } })}
                      disabled={formData.clockStyle == 'none' || isMod}
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="flex-row">
                    <label htmlFor="clockBackgroundColor">{getClockName()} Background Color</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('clockBackgroundColor')}
                      />
                      {tooltip === 'clockBackgroundColor' && (
                        <p className="tooltip shadow-lg">
                          {backgroundClockTooltip()}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      opacity={true}
                      name="clockBackgroundColor"
                      value={formData.clockBackgroundColor || 'rgb(0, 0, 0, 1)'}
                      onValueChange={(value, name) => handleChange({ target: { value, name } })}
                      disabled={formData.clockStyle == 'none' || isMod}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="settings-sections-wrapper">
              <div className="settings-section" style={{ paddingRight: "2em" }}>
                <div className="flex-row">
                  <label htmlFor="chatOverlayFont">Chat Font Style</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('chatOverlayFont')}
                    />
                    {tooltip === 'chatOverlayFont' && (
                      <p className="tooltip shadow-lg">
                        <strong>Set the font of your chat.</strong><br />
                          We offer a variety of fonts to choose from.<br />
                        <br />
                        Default: Jaldi
                      </p>
                    )}
                  </div>
                </div>
                <div className="tooltip-input">
                  <FontSelector
                    name="textFont"
                    fonts={fontOptions}
                    styles={fontStyles}
                    placeholder="Font"
                    onFontChange={handleFontChange}
                    value={fontOptions.find((x) => x.value == formData.textFont) || 'Default'}
                    disabled={isMod}
                  />
                </div>
                <div className="input-row">
                  <div className="flex-row">
                    <div className="icon-btn tooltip-wrapper">
                      <label htmlFor="textFontSize">Title Font Size (pixels)</label>
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <input
                      name="titleFontSize"
                      type="number"
                      value={formData.titleFontSize || '48'}
                      onChange={handleChange}
                      disabled={isMod}
                    />
                  </div>
                  <div className="flex-row">
                    <div className="icon-btn tooltip-wrapper">
                      <label htmlFor="textFontSize">Timer Font Size (pixels)</label>
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <input
                      name="timerFontSize"
                      type="number"
                      value={formData.timerFontSize || '30'}
                      onChange={handleChange}
                      disabled={isMod}
                    />
                  </div>
                  <div className="flex-row">
                    <div className="icon-btn tooltip-wrapper">
                      <label htmlFor="textFontSize">Description Font Size (pixels)</label>
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <input
                      name="textFontSize"
                      type="number"
                      value={formData.textFontSize || '20'}
                      onChange={handleChange}
                      disabled={isMod}
                    />
                  </div>
                </div>
              </div>
              <div className="settings-section subathon-section subathon-preview">
                <h4>Preview</h4>
                <div className={"subathon-container " + formData.textFont}
                  style={{ backgroundColor: formData.backgroundColor }}>
                  <h3 style={{ fontSize: formData.titleFontSize, color: formData.textColor }}>{formData.title}</h3>
                  {formData.clockStyle == 'clock' ? (
                    <Clock
                      hour={formData.timer.hours}
                      minute={formData.timer.minutes}
                      second={formData.timer.seconds}
                      primaryColor={formData.clockPrimaryColor}
                      secondaryColor={formData.clockSecondaryColor}
                      backgroundColor={formData.clockBackgroundColor}
                      radius={110}
                      roman={false}
                    />
                  ) : formData.clockStyle == 'clock-roman' ? (
                    <Clock
                      hour={formData.timer.hours}
                      minute={formData.timer.minutes}
                      second={formData.timer.seconds}
                      primaryColor={formData.clockPrimaryColor}
                      secondaryColor={formData.clockSecondaryColor}
                      backgroundColor={formData.clockBackgroundColor}
                      radius={110}
                      roman={true}
                    />
                  ) : formData.clockStyle == 'hourglass' ? (
                    <Hourglass
                      value={getTotalSeconds(formData.timer)}
                      max={formData.defaultTimer}
                      primaryColor={formData.clockPrimaryColor}
                      secondaryColor={formData.clockSecondaryColor}
                      backgroundColor={formData.clockBackgroundColor}
                      playing={playing}
                    />
                  ) : formData.clockStyle && formData.clockStyle.match(/^goal/g) ? (
                    <ProgressBar
                      value={getTotalSeconds(formData.timer)}
                      theme={formData.clockStyle.replace(/^goal-/g, '')}
                      max={formData.defaultTimer}
                      primaryColor={formData.clockPrimaryColor}
                      secondaryColor={formData.clockSecondaryColor}
                      backgroundColor={formData.clockBackgroundColor}
                      textColor={formData.textColor}
                      isMonetary={false}
                      fontSize={formData.fontSize}
                      length="750px"
                    />
                  ) : <div/>}
                  <div className="flex-row time"
                    style={{ fontSize: formData.timerFontSize, color: formData.timerColor }}>
                      <FlipNumbers
                        height={formData.timerFontSize}
                        width={formData.timerFontSize}
                        background='transparent'
                        play
                        perspective={300}
                        numbers={String(formData.timer.hours).padStart(2, '0')}
                      />:
                      <FlipNumbers
                        height={formData.timerFontSize}
                        width={formData.timerFontSize}
                        background='transparent'
                        play
                        perspective={300}
                        numbers={String(formData.timer.minutes).padStart(2, '0')}
                      />:
                      <FlipNumbers
                        height={formData.timerFontSize}
                        width={formData.timerFontSize}
                        background='transparent'
                        play
                        perspective={300}
                        numbers={String(formData.timer.seconds).padStart(2, '0')}
                      />
                  </div>
                  <div style={{ color: formData.textColor, fontSize: formData.textFontSize }} className="description">
                    {formData.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row">
        <button className="primary-btn" type="submit" disabled={saving}>
          Save
        </button>
        <button className="secondary-btn" onClick={handleCancel} type="button" disabled={saving}>
          Cancel
        </button>
      </div>
      <FormAlert alertState={alertState} width={400} />
    </form>
  );
};

export default SubathonSettings;
