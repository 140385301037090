import React from 'react';
import '../css/clock.css';

const Clock = ({ hour, minute, second, 
  primaryColor, secondaryColor, backgroundColor,
  roman = false,
  fontSize = 20, radius = 50 }) => {
  
  const convertToRoman = (num) => {
    const romanNumerals = [
      { value: 1000, symbol: "M" },
      { value: 900, symbol: "CM" },
      { value: 500, symbol: "D" },
      { value: 400, symbol: "CD" },
      { value: 100, symbol: "C" },
      { value: 90, symbol: "XC" },
      { value: 50, symbol: "L" },
      { value: 40, symbol: "XL" },
      { value: 10, symbol: "X" },
      { value: 9, symbol: "IX" },
      { value: 5, symbol: "V" },
      { value: 4, symbol: "IV" },
      { value: 1, symbol: "I" }
    ];

    let result = "";

    // Loop through the lookup table
    for (let i = 0; i < romanNumerals.length; i++) {
      const { value, symbol } = romanNumerals[i];

      // Keep subtracting the value from num while adding the symbol to the result
      while (num >= value) {
        result += symbol;
        num -= value;
      }
    } 

    return result;
  }

  return (
    <div className="visual-clock" style={{
        borderColor: secondaryColor,
        backgroundColor: backgroundColor,
        maxWidth: radius * 2,
        maxHeight: radius * 2,
        minWidth: radius * 2,
        minHeight: radius * 2,
        fontSize,
      }}
    >
      <div className="clock-pin" style={{ backgroundColor: secondaryColor }} />
      <div className="hour-hand" style={{
          backgroundColor: secondaryColor,
          transform: `rotateZ(${((hour + minute/60) % 12) * 30}deg)`,
          height: `${Math.floor(radius / 2)}px`,
        }}
      />
      <div className="minute-hand" style={{
          backgroundColor: secondaryColor,
          transform: `rotateZ(${(minute + second/60) * 6}deg)`,
          height: `${radius * 0.7}px`,
        }}
      />
      <div className="second-hand" style={{
          backgroundColor: primaryColor,
          transform: `rotateZ(${second * 6}deg)`,
          height: `${radius * 0.75}px`,
        }}
      />
      <div className="numbers">
      {Array(12).fill(0).map((_, i) =>
        <span key={'number' + i} style={{
            color: primaryColor,
            transform: `translate(${(radius - fontSize - 1) * Math.sin((i+1)*Math.PI/6)}px,
              ${(radius - fontSize + 2) * (1 - Math.cos((i+1)*Math.PI/6))}px)`,
          }}
        >
          {roman ? convertToRoman(i+1) : (i+1)}
        </span>
      )}
      </div>
    </div>
  );
};

export default Clock;
